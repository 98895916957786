import React, { useCallback, useContext, useMemo } from 'react';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import { observer } from 'mobx-react-lite';
import SuccessButton from '../../../../../../../../@Future/Component/Generic/Button/Variant/SuccessButton/SuccessButton';
import useTypes from '../../../../../Type/Api/useTypes';
import { createTransactionalModel, getModel } from '../../../../../../../../@Util/TransactionalModelV2/Model/TransactionalModel';
import CurrentUserContext from '../../../../../../User/CurrentUserContext';
import useActiveTimeRegistration from '../../../../../Timetracker/Hooks/useActiveTimeRegistration';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import equalsEntity from '../../../../../../../../@Api/Entity/Bespoke/equalsEntity';
import ViewerEntityContext from '../../../../Context/ViewerEntity/ViewerEntityContext';
import useEndTimeRegistration from '../../../../../Timetracker/Hooks/useEndTimeRegistration';
import LocalizedText from '../../../../../../Localization/LocalizedText/LocalizedText';

export interface TimeRegistrationButtonProps
{
    entity: Entity;
    activity: Entity;
}

const TimeRegistrationButton: React.FC<TimeRegistrationButtonProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(CurrentUserContext);

        const canCreateTimeRegistration =
            useMemo(
                () =>
                    currentUserStore.rightProfile.canCreate(types.TimeRegistration.Type),
                [
                    currentUserStore,
                    types
                ]
            );

        const createTimeRegistration =
            useCallback(
                () =>
                {
                    const timeRegistration =
                        createTransactionalModel(
                            new Entity(types.TimeRegistration.Type)
                                .initialize());

                    timeRegistration.updateRelationship(
                        true,
                        types.Activity.RelationshipDefinition.TimeRegistrations,
                        createTransactionalModel(getModel(props.entity)));

                    timeRegistration.updateRelationship(
                        true,
                        types.Relationship.Person.Contact.Employee.RelationshipDefinition.TimeRegistrations,
                        createTransactionalModel(currentUserStore.employeeEntity));

                    timeRegistration.updateRelationship(
                        true,
                        types.Relationship.RelationshipDefinition.TimeRegistrations,
                        createTransactionalModel(
                            props.entity.getRelatedEntityByDefinition(
                                true,
                                types.Relationship.RelationshipDefinition.Activities)));

                    timeRegistration.updateRelationship(
                        false,
                        types.TimeRegistration.RelationshipDefinition.Activity,
                        createTransactionalModel(props.activity));

                    timeRegistration.setValueByField(
                        types.TimeRegistration.Field.StartDate,
                        new Date());

                    return timeRegistration.checkAndDoCommit();
                },
                [
                    types,
                    currentUserStore,
                    props.entity,
                    props.activity
                ]);

        const activeTimeRegistration = useActiveTimeRegistration();
        const endTimeRegistration = useEndTimeRegistration();
        const stop =
            useCallback(
                () =>
                {
                    endTimeRegistration(
                        activeTimeRegistration,
                        new Date());
                },
                [
                    endTimeRegistration,
                    activeTimeRegistration
                ]);
        const activity =
            useRelatedEntity(
                activeTimeRegistration,
                types.Activity.RelationshipDefinition.TimeRegistrations,
                true);
        const timeActivity =
            useRelatedEntity(
                activeTimeRegistration,
                types.TimeRegistration.RelationshipDefinition.Activity,
                false);
        const viewerEntity = useContext(ViewerEntityContext);

        if (!canCreateTimeRegistration)
        {
            return null;
        }

        if (activeTimeRegistration)
        {
            if (equalsEntity(viewerEntity, activity)
                && equalsEntity(timeActivity, props.activity))
            {
                return <SuccessButton
                    icon="timer_off"
                    label={
                        <LocalizedText
                            code="TimeRegistration.StopWithActivity"
                            value="Stoppen met ${name}"
                            name={props.activity.name?.toLowerCase()}
                        />
                    }
                    onClick={stop}
                    fullWidth
                    size="sm"
                />;
            }
            else
            {
                return null;
            }
        }
        else
        {
            return <SuccessButton
                icon="timer"
                label={props.activity.name}
                onClick={createTimeRegistration}
                fullWidth
                size="sm"
            />;
        }
    };

export default observer(TimeRegistrationButton);
