import { observable } from 'mobx';
import Input from '../../../../@Component/Domain/Multiplayer/Model/Input/Input';
import FieldMapping from './FieldMapping';
import Computation from '../../Function/Computation/Computation';
import AutomationDependencyContext from '../../AutomationDependencyContext';
import resolveInputFromDescriptor from '../../../../@Component/Domain/Multiplayer/Model/Input/Api/resolveInputFromDescriptor';
import getComputationFromDescriptor from '../../Api/getComputationFromDescriptor';
import Dependency from '../../Parameter/Dependency';
import Validation from '../../Validation/Validation';
import localizeText from "../../../Localization/localizeText";

export default class ValueFieldMapping extends FieldMapping<Input>
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;

    // ------------------------ Constructor -------------------------

    constructor(field: Input,
                value: Computation<any, any>)
    {
        super(field);

        this.value = value;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    validate(): Validation[]
    {
        if (this.value)
        {
            return this.value.validate();
        }
        else
        {
            return [
                new Validation(
                    'Error',
                    localizeText(
                        "Generic.InvalidFieldValue",
                        "Je hebt geen geldige waarde ingevuld voor het veld: '${field}'",
                        {
                            field: this.field.getName()
                        }
                    )
                )
            ];
        }
    }

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    getDependencies(): Dependency[]
    {
        return this.value.getDependencies();
    }

    augmentDescriptor(descriptor)
    {
        descriptor.type = 'Value';
        descriptor.value = this.value.toDescriptor();
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new ValueFieldMapping(
            resolveInputFromDescriptor(descriptor.field),
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------

}
