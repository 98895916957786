import React, { useCallback } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import Action from '../../../../../../../../../@Api/Automation/Function/Action/Action';
import Menu from '../../../../../../../../../@Future/Component/Generic/Menu/Menu';
import Item from '../../../../../../../../../@Future/Component/Generic/Menu/Item/Item';
import CreateEntityAction from '../../../../../../../../../@Api/Automation/Function/Action/CreateEntityAction';
import UpdateEntityAction from '../../../../../../../../../@Api/Automation/Function/Action/UpdateEntityAction';
import ForEachAction from '../../../../../../../../../@Api/Automation/Function/Action/ForEachAction';
import Card from '../../../../../../../../../@Future/Component/Generic/Card/Card';
import HoverCard from '../../../../../../../../../@Future/Component/Generic/Card/HoverCard/HoverCard';
import Popper from '../../../../../../../../../@Future/Component/Generic/Popper/Popper';
import useSwitch from '../../../../../../../../../@Util/Switch/useSwitch';
import uuid from '../../../../../../../../../@Util/Id/uuid';
import CompositeAction from '../../../../../../../../../@Api/Automation/Function/Action/CompositeAction';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { loadModuleDirectly } from '../../../../../../../../../@Util/DependencyInjection/index';
import DynamicFunctionRepositoryManager from '../../../../../../../../../@Api/Automation/Function/Dynamic/Repository/DynamicFunctionRepositoryManager';
import DynamicActionConstructor from './DynamicActionConstructor';
import ConditionalAction from '../../../../../../../../../@Api/Automation/Function/Action/ConditionalAction';
import CompositePredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/CompositePredicate';
import { LogicalOperator } from '../../../../../../../DataObject/Model/LogicalOperator';
import ConditionalInAction from '../../../../../../../../../@Api/Automation/Function/Action/ConditionalInAction';
import ComparisonPredicate from '../../../../../../../../../@Api/Automation/Function/Computation/Predicate/ComparisonPredicate';
import { Comparator } from '../../../../../../../DataObject/Model/Comparator';
import { classNames } from '../../../../../../../../../@Future/Util/Class/classNames';
import Icon from '../../../../../../../../../@Future/Component/Generic/Icon/Icon';
import styles from './ActionConstructor.module.scss';
import DeleteEntityAction from '../../../../../../../../../@Api/Automation/Function/Action/DeleteEntityAction';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../../../../../@Api/Localization/localizeText';
import { getCopiedActionFromClipboard, hasCopiedActionInClipboard } from '../Clipboard/ActionClipboard';
import isFunctionCompatibleWithParameterDictionary from '../../../../../../../../../@Api/Automation/Api/isFunctionCompatibleWithParameterDictionary';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import getActionFromDescriptor from '../../../../../../../../../@Api/Automation/Api/getActionFromDescriptor';
import AutomationDependencyContext from '../../../../../../../../../@Api/Automation/AutomationDependencyContext';
import ExceptionAction from '../../../../../../../../../@Api/Automation/Function/Action/ExceptionAction';
import DynamicParameterAssignment from '../../../../../../../../../@Api/Automation/Function/Dynamic/DynamicParameterAssignment';
import InvocationAction from '../../../../../../../../../@Api/Automation/Function/Action/InvocationAction';
import CreateRelationshipAction from '../../../../../../../../../@Api/Automation/Function/Action/CreateRelationshipAction';
import DeleteRelationshipAction from '../../../../../../../../../@Api/Automation/Function/Action/DeleteRelationshipAction';
import SftpUploadAction from '../../../../../../../../../@Api/Automation/Function/Action/SftpUploadAction';
import EmptyValue from '../../../../../../../../../@Api/Automation/Value/EmptyValue';
import PrimitiveValue from '../../../../../../../../../@Api/Automation/Value/PrimitiveValue';
import { DataObject } from '../../../../../../../DataObject/Model/DataObject';
import GenerateNumberInEntityAction from '../../../../../../../../../@Api/Automation/Function/Action/GenerateNumberInEntityAction';

export type ActionConstructorMode = 'Button' | 'Line'

export interface ActionConstructorProps
{
    onConstruct: (action: Action<any, any>, afterIdx?: number) => void;
    mode: ActionConstructorMode;
    context: FunctionContext;
}

export interface ActionType
{
    id: string;
    name: string;
    construct: () => void;
}

const ActionConstructor: React.FC<ActionConstructorProps> =
    props =>
    {
        const [ isOpen, open, close ] = useSwitch(false);

        const onConstruct =
            useCallback(
                (action: Action<any, any>) =>
                {
                    props.onConstruct(action);
                    close();
                },
                [
                    props.onConstruct,
                    close
                ]);

        const types =
            useComputed<ActionType[]>(
                () => [
                    {
                        id: 'CreateEntity',
                        name: localizeText('Action.CreateEntity', 'Record aanmaken'),
                        construct:
                            () =>
                                onConstruct(
                                    new CreateEntityAction(undefined))
                    },
                    {
                        id: 'UpdateEntity',
                        name: localizeText('Action.UpdateEntity', 'Record muteren'),
                        construct:
                            () =>
                                onConstruct(
                                    new UpdateEntityAction(undefined, undefined, undefined))
                    },
                    {
                        id: 'DeleteEntity',
                        name: localizeText('Action.DeleteEntity', 'Record verwijderen'),
                        construct:
                            () =>
                                onConstruct(
                                    new DeleteEntityAction(undefined))
                    },
                    {
                        id: 'CreateRelationship',
                        name: localizeText('Action.CreateRelationship','Records koppelen'),
                        construct:
                            () =>
                                onConstruct(
                                    new CreateRelationshipAction(
                                        undefined,
                                        undefined,
                                        undefined
                                    )
                                )
                    },
                    {
                        id: 'DeleteRelationship',
                        name: localizeText('Action.DeleteRelationship','Records ontkoppelen'),
                        construct:
                            () =>
                                onConstruct(
                                    new DeleteRelationshipAction(
                                        undefined,
                                        undefined,
                                        undefined
                                    )
                                )
                    },
                    {
                        id: 'GenerateNumberInEntity',
                        name: localizeText('Action.GenerateNumber', 'Genereer nummer voor record'),
                        construct:
                            () =>
                                onConstruct(
                                    new GenerateNumberInEntityAction(
                                        undefined,
                                        undefined
                                    )
                                )
                    },
                    {
                        id: 'ForEach',
                        name: localizeText('Action.ForEach', 'Voor elk element uit collectie...'),
                        construct:
                            () =>
                                onConstruct(
                                    new ForEachAction(
                                        uuid(),
                                        undefined,
                                        new CompositeAction([])))
                    },
                    {
                        id: 'Conditional',
                        name: localizeText('Action.IfThenElse', 'Als ..., dan ..., of anders ...'),
                        construct:
                            () =>
                                onConstruct(
                                    new ConditionalAction(
                                        [
                                            new ConditionalInAction(
                                                new CompositePredicate(
                                                    LogicalOperator.And,
                                                    [
                                                        new ComparisonPredicate(
                                                            undefined,
                                                            Comparator.Equals,
                                                            undefined)
                                                    ]),
                                                new CompositeAction([]))
                                        ],
                                        new CompositeAction([])))
                    },
                    {
                        id: 'Exception',
                        name: localizeText('Action.Exception', 'Foutmelding geven'),
                        construct:
                            () =>
                                onConstruct(new ExceptionAction(undefined))
                    },
                    {
                        id: 'Invocation',
                        name: localizeText('Action.Invocation', 'Actie aanroepen'),
                        construct:
                            () =>
                                onConstruct(
                                    new InvocationAction(
                                        undefined,
                                        new DynamicParameterAssignment()))
                    },
                    {
                        id: 'FtpUpload',
                        name: localizeText('Action.SftpUpload', 'Upload bestand in SFTP server'),
                        construct:
                            () =>
                                onConstruct(
                                    new SftpUploadAction(
                                        EmptyValue.instance,
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                'sftp.example.com'
                                            )
                                        ),
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Number',
                                                123
                                            )
                                        ),
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                'username'
                                            )
                                        ),
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                'password'
                                            )
                                        ),
                                        new PrimitiveValue(
                                            DataObject.constructFromTypeIdAndValue(
                                                'Text',
                                                'path/to/upload'
                                            )
                                        ),
                                    )
                                )
                    },
                    ...hasCopiedActionInClipboard() && isFunctionCompatibleWithParameterDictionary(getCopiedActionFromClipboard(), props.context.parameterDictionary)
                        ?
                            [
                                {
                                    id: 'PasteFromClipboard',
                                    name: localizeText('Generic.PasteFromClipboard', 'Plakken vanuit klembord'),
                                    construct:
                                        async () =>
                                        {
                                            const actionDescriptor = getCopiedActionFromClipboard().toDescriptor();
                                            const copiedAction =
                                                await getActionFromDescriptor(
                                                    actionDescriptor,
                                                    new AutomationDependencyContext(props.context.parameterDictionary));

                                            return onConstruct(copiedAction);
                                        }
                                }
                            ]
                        :
                            []
                ],
                [
                    onConstruct,
                    props.context
                ]);

        const repositories =
            useComputed(
                () =>
                    loadModuleDirectly(DynamicFunctionRepositoryManager).repositories,
                []);

        return <Popper
            reference={
                props.mode === 'Button'
                    ?
                        <HoverCard
                            onClick={open}
                        >
                            <LocalizedText
                                code="AutomationEditor.AddActionButton"
                                value="+ Actie"
                            />
                        </HoverCard>
                    :
                        <div
                            className={styles.container}
                        >
                            <div
                                className={
                                    classNames(
                                        styles.root,
                                        isOpen && styles.open,
                                        styles.vertical)}
                                onClick={open}
                            >
                                <div
                                    className={styles.line}
                                />
                                <div
                                    className={styles.icon}
                                >
                                    <Icon
                                        icon="add"
                                        size={11}
                                    />
                                </div>
                            </div>
                        </div>
            }
            popper={
                <Card>
                    <ViewGroup
                        orientation="horizontal"
                        spacing={0}
                    >
                        <ViewGroupItem
                            ratio={1}
                        >
                            <Menu>
                                {
                                    types.map(
                                        type =>
                                            <Item
                                                key={type.id}
                                                name={type.name}
                                                onClick={type.construct}
                                            />)
                                }
                            </Menu>
                        </ViewGroupItem>
                        {
                            repositories.map(
                                repository =>
                                    <ViewGroupItem
                                        key={repository.id}
                                        ratio={1}
                                    >
                                        <DynamicActionConstructor
                                            {...props}
                                            repository={repository}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                </Card>
            }
            open={isOpen}
            onClose={close}
        />;
    };

export default observer(ActionConstructor);
