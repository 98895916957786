import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ComputationEditor, { ComputationEditorProps } from '../../ComputationEditor';
import PrimitiveValueType from '../../../../../../../../../../@Api/Automation/Value/Type/PrimitiveValueType';
import { runInAction } from 'mobx';
import Computation from '../../../../../../../../../../@Api/Automation/Function/Computation/Computation';
import StartOfDayComputation from '../../../../../../../../../../@Api/Automation/Function/Computation/StartOfDayComputation';
import ComputationBox from '../../Box/ComputationBox';
import Input from '../../../../../../../../../../@Future/Component/Generic/Input/Input/Input';
import localizeText from '../../../../../../../../../../@Api/Localization/localizeText';

export interface StartOfDayComputationEditorProps extends ComputationEditorProps<PrimitiveValueType, StartOfDayComputation>
{

}

const StartOfDayComputationEditor: React.FC<StartOfDayComputationEditorProps> =
    props =>
    {
        const setDate =
            useCallback(
                (date?: Computation<any, any>) =>
                    runInAction(
                        () =>
                            props.value.date = date),
                [
                    props.value
                ]);

        return <Input
            label={
                localizeText(
                    'StartOfDay',
                    'Start van dag...'
                )
            }
            labelPosition="left"
        >
            <ComputationBox>
                <ComputationEditor
                    context={props.context}
                    value={props.value.date}
                    onChange={setDate}
                />
            </ComputationBox>
        </Input>;
    };

export default observer(StartOfDayComputationEditor);
