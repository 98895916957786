import React, { useCallback } from 'react';
import { GoogleMapLocation } from './GoogleMap';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import openWindow from '../../../@Util/Window/openWindow';
import resolveUrlFromUserInput from '../../../@Util/Url/resolveUrlFromUserInput';
import Card from '../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../@Future/Component/Generic/Card/CardInset';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import TypeAndName from '../../Domain/Entity/Viewer/Shared/TypeAndName/TypeAndName';
import Centered from '../../../@Future/Component/Generic/Centered/Centered';
import RightAlignedButtonGroup from '../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import { primaryColor } from '../../../@Resource/Theme/Theme';
import LabelButton from '../../../@Future/Component/Generic/Button/Variant/Label/LabelButton';
import LocalizedText from '../../Domain/Localization/LocalizedText/LocalizedText';

export interface GoogleMapsPopupProperties
{
    lat: number;
    lng: number;
    location: GoogleMapLocation;
}

const useStyles =
    makeStyles({
        popupContent:
        {
            fontSize: 14,
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            lineHeight: 1.5,
        }
    });

export const GoogleMapsPopup: React.FC<GoogleMapsPopupProperties> = observer(
    ({
        location
     }) =>
{
    const classes = useStyles();

    const onOpenRelationShip =
        useCallback(
            () =>
            {
                if (location.entity)
                {
                    openWindow(
                        `/entity/${location.entity.uuid}`,
                        '_blank'
                    )
                }
            },
            [
                location
            ]
        )

    const onOpenGoogleMaps =
        useCallback(
            () =>
            {
                if (location.link)
                {
                    openWindow(
                        resolveUrlFromUserInput(location.link),
                        '_blank'
                    )
                }
            },
            [
                location
            ]
        )

    return <Card
        style={{width: 'max-content'}}
    >
        <CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={10}
            >
                <ViewGroupItem
                    className={classes.popupContent}
                >
                    <TypeAndName
                        entity={location.entity}
                        readonly
                    />
                </ViewGroupItem>

                <ViewGroupItem>
                    <Centered
                        horizontal
                    >
                        <RightAlignedButtonGroup>
                            <LabelButton
                                label={
                                    <LocalizedText
                                        code="GoogleMapsPopup.OpenEntity"
                                        value="Open entiteit"
                                    />
                                }
                                onClick={onOpenRelationShip}
                                filled
                                color={primaryColor}
                                disabled={!location.entity}
                            />
                            <LabelButton
                                label={
                                    <LocalizedText
                                        code="GoogleMapsPopup.OpenGoogleMaps"
                                        value="Google Maps"
                                    />
                                }
                                onClick={onOpenGoogleMaps}
                                filled
                                color={primaryColor}
                                disabled={!location.link}
                            />
                        </RightAlignedButtonGroup>
                    </Centered>
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>
    </Card>

});
