import { computed, observable } from 'mobx';
import { Entity } from '../../../../@Api/Model/Implementation/Entity';
import { EntityTypeStore } from '../Type/EntityTypeStore';
import { EntityType } from '../../../../@Api/Model/Implementation/EntityType';
import { CurrentUserStore } from '../../User/CurrentUserStore';
import equalsEntity from '../../../../@Api/Entity/Bespoke/equalsEntity';
import Role, { Privilege } from '../../Role/Model/Role';
import { CommitContext } from '../../../../@Api/Entity/Commit/Context/CommitContext';
import { EntityRelationshipDefinition } from '../../../../@Api/Model/Implementation/EntityRelationshipDefinition';
import FunctionContext from '../../../../@Api/Automation/Function/FunctionContext';
import ParameterAssignment from '../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../@Api/Automation/Value/EntityValue';
import { RoleParams } from '../../Role/Model/RoleParams';
import Right from '../../Role/Model/Right';
import {loadModuleDirectly} from "../../../../@Util/DependencyInjection";

export class RightProfile
{
    // ------------------------ Dependencies ------------------------

    // ------------------------- Properties -------------------------

    @observable.ref entityTypeStore: EntityTypeStore;
    @observable.ref currentUserStore: CurrentUserStore;
    @observable.shallow ownedPacks: Entity[];
    @observable.ref environmentPack: Entity;
    @observable.ref userPack: Entity;
    @observable.ref role: Role;

    // ------------------------ Constructor -------------------------

    constructor(entityTypeStore: EntityTypeStore,
                currentUserStore: CurrentUserStore)
    {
        this.entityTypeStore = entityTypeStore;
        this.currentUserStore = currentUserStore;
        this.ownedPacks = observable.array<Entity>();
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    @computed
    get metadataEntityTypes(): Set<EntityType>
    {
        return new Set<EntityType>(
            this.entityTypeStore.types
                .filter(
                    type =>
                        type.entity &&
                        type.entity.getObjectValueByField(this.entityTypeStore.bespoke.types.EntityType.Field.IsMetadata) === true));
    }

    @computed
    get isPackOwner(): boolean
    {
        return this.ownedPacks.length > 0;
    }

    @computed
    get ownedEnvironmentPackOrAnyOwnedPack(): Entity | undefined
    {
        if (this.ownedPacks.some(ownedPack => equalsEntity(ownedPack, this.environmentPack)))
        {
            return this.environmentPack;
        }
        else
        {
            return this.ownedPacks.find(() => true);
        }
    }

    // --------------------------- Stores ---------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    static fromEntities(ownedPacks: Entity[],
                        environmentPack: Entity,
                        userPack: Entity,
                        role: Role,
                        entityTypeStore: EntityTypeStore,
                        currentUserStore: CurrentUserStore): RightProfile
    {
        const profile =
            new RightProfile(
                entityTypeStore,
                currentUserStore);

        profile.ownedPacks.push(...ownedPacks);
        profile.environmentPack = environmentPack;
        profile.userPack = userPack;
        profile.role = role;

        return profile;
    }

    public canRead(entityType: EntityType): boolean
    {
        return this.role.isPermissionGrantedForType(
            entityType,
            'canRead');
    }

    public getReadableSubtypes(entityType: EntityType): EntityType[]
    {
        return entityType.getAllInstantiableTypes(false)
            .filter(
                type =>
                    this.canRead(type));
    }

    public canReadSubtype(entityType: EntityType): boolean
    {
        return this.getReadableSubtypes(entityType).length > 0;
    }

    public canCreate(entityType: EntityType): boolean
    {
        return this.canCreateType(entityType)
            && this.hasAllowedMutationPack(entityType);
    }

    public canCreateTypeOrSubtype(entityType: EntityType): boolean
    {
        return this.canCreateType(entityType)
            || this.canCreateSubtype(entityType);
    }

    public canCreateType(entityType: EntityType): boolean
    {
        return this.role.isPermissionGrantedForType(
            entityType,
            'canCreate');
    }

    public canCreateRelationshipType(relationshipType: EntityRelationshipDefinition): boolean
    {
        return this.role.isPermissionGrantedForRelationshipType(
            relationshipType,
            relationshipType.parentEntityType,
            relationshipType.childEntityType,
            'canCreate'
        );
    }

    public getCreatableSubtypes(entityType: EntityType): EntityType[]
    {
        return entityType.getAllInstantiableTypes(false)
            .filter(
                type =>
                    this.canCreate(type));
    }

    public canCreateSubtype(entityType: EntityType): boolean
    {
        return this.getCreatableSubtypes(entityType).length > 0;
    }

    public canUpdate(
        entity: Entity,
        commitContext?: CommitContext
    ): boolean
    {
        return this.canUpdateType(entity.entityType)
            && this.hasAllowedMutationPack(entity.entityType)
            && this.isAllowedToMutate(entity, commitContext)
            && this.isPermissionConditionallyGranted(
                entity,
                this.role.getTypeRight(entity.entityType),
                'canUpdate',
                commitContext
            );
    }

    public canUpdateType(entityType: EntityType): boolean
    {
        return this.role.isPermissionGrantedForType(
            entityType,
            'canUpdate');
    }

    public canUpdateRelationship(
        entityType: EntityType,
        relationship: EntityRelationshipDefinition,
        isParent: boolean
    )
    {
        return this.role.isPermissionGrantedForRelationshipTypeFromSide(
            entityType,
            relationship,
            isParent,
            'canUpdate'
        )
    }

    public canDelete(entity: Entity): boolean
    {
        return this.canDeleteType(entity.entityType)
            && this.hasAllowedMutationPack(entity.entityType)
            && this.isAllowedToMutate(entity)
            && this.isPermissionConditionallyGranted(
                entity,
                this.role.getTypeRight(entity.entityType),
                'canDelete'
            );
    }

    public canDeleteType(entityType: EntityType): boolean
    {
        return this.role.isPermissionGrantedForType(
            entityType,
            'canDelete');
    }

    public isMetadataType(entityType: EntityType): boolean
    {
        return this.metadataEntityTypes.has(entityType);
    }

    public getPackForNewType(entityType: EntityType): Entity
    {
        if (this.isMetadataType(entityType))
        {
            if (this.ownedPacks.length > 0)
            {
                if (this.ownedPacks.some(
                    pack => equalsEntity(pack, this.environmentPack)))
                {
                    return this.environmentPack;
                }
                else
                {
                    return this.ownedPacks[0];
                }
            }
            else
            {
                return undefined;
            }
        }
        else
        {
            return this.environmentPack;
        }
    }

    public getAllowedMutationPacks(entityType: EntityType): Entity[]
    {
        // Only return owned packs in case of metadata, and do not include the environment pack twice in case
        // that pack is owned as well.
        if (this.isMetadataType(entityType)
            || this.ownedPacks.some(
                pack =>
                    equalsEntity(pack, this.environmentPack))
            || this.environmentPack === undefined)
        {
            return Array.from(this.ownedPacks);
        }
        else
        {
            return Array.from(this.ownedPacks)
                .concat(this.environmentPack);
        }
    }

    public hasAllowedMutationPack(entityType: EntityType): boolean
    {
        return this.getAllowedMutationPacks(entityType).length > 0;
    }

    public isAllowedToMutate(
        entity: Entity,
        commitContext?: CommitContext
    ): boolean
    {
        const pack =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities,
                commitContext
            );

        if (!pack)
        {
            // LD: Leave this comment here for debugging purposes
            // console.warn('cannot find pack for entity', entity);
            return false;
        }

        return this.isAllowedToMutatePack(entity.entityType, pack)
            || equalsEntity(entity, this.currentUserStore.accountEntity);
    }

    public isAllowedToMutatePack(entityType: EntityType,
                                 pack: Entity)
    {
        return this.getAllowedMutationPacks(entityType)
            .some(
                mutationPack =>
                    equalsEntity(mutationPack, pack));
    }

    public isPackOwnerOfEntityPack(entity: Entity): boolean
    {
        const entityPack =
            entity.getRelatedEntityByDefinition(
                true,
                this.entityTypeStore.bespoke.types.Pack.RelationshipDefinition.Entities);

        return this.isOwnerOfPack(entityPack);
    }

    public isOwnerOfPack(pack: Entity): boolean
    {
        return this.ownedPacks
            .some(
                checkPack =>
                    checkPack.id === pack.id);
    }

    // ----------------------- Private logic ------------------------

    isPermissionConditionallyGranted(
        entity: Entity,
        right: Right | undefined,
        privilege: Privilege,
        commitContext?: CommitContext
    )
    {
        if (right)
        {
            const predicate = right?.getPredicate(privilege);
            const parameters = right?.parameters;

            if (predicate && parameters)
            {
                const parameterAssignment = new ParameterAssignment();
                parameterAssignment.setValue(
                    parameters.getParameterById(RoleParams.Entity),
                    new EntityValue(entity)
                );
                parameterAssignment.setValue(
                    parameters.getParameterById(RoleParams.Me),
                    new EntityValue(
                        loadModuleDirectly(CurrentUserStore).employeeEntity
                    )
                );

                const predicateContext = new FunctionContext(
                    parameters,
                    parameterAssignment,
                    commitContext
                );

                return predicate.synchronouslyEvaluate(predicateContext);
            }
            else
            {
                return true;
            }
        }
        else
        {
            return true;
        }
    }

    /*isPermissionConditionallyGrantedForRelationshipType(
        entity: Entity,
        isParent: boolean,
        right: Right | undefined,
        privilege: Privilege,
        commitContext?: CommitContext
    )
    {
        if (right)
        {
            const predicate = right?.getPredicate(privilege);
            const parameters = right?.parameters;

            if (predicate && parameters)
            {
                const parameterAssignment = new ParameterAssignment();
                if (isParent)
                {
                    parameterAssignment.setValue(
                        parameters.getParameterById(RoleParams.ParentEntity),
                        new EntityValue(entity)
                    );
                }
                else
                {
                    parameterAssignment.setValue(
                        parameters.getParameterById(RoleParams.ChildEntity),
                        new EntityValue(entity)
                    );
                }

                const predicateContext = new FunctionContext(
                    parameters,
                    parameterAssignment,
                    commitContext
                );

                return predicate.synchronouslyEvaluate(predicateContext);
            }
            else
            {
                return true;
            }
        }
        else
        {
            return true;
        }
    }*/
}
