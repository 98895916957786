import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PrimaryButton from '../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import RightAlignedButtonGroup from '../../../../../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import FormContext from '../../../Context/FormContext';
import LocalizedText from '../../../../../Localization/LocalizedText/LocalizedText';
import uuid from 'uuid';
import useDebouncedCallback from '../../../../../../../@Util/Debounce/useDebouncedCallback';
import GoogleTagManagerContainerIdProvider from "../../../Page/Wrapper/GoogleTagManagerContainerIdProvider";

export interface SubmitButtonViewerProps
{
    buttonId?: string
}

const SubmitButtonViewer: React.FC<SubmitButtonViewerProps> =
    props =>
    {
        const context = useContext(FormContext);
        const [ isSubmitting, setIsSubmitting] = useState<boolean>(false);
        const googleTagManagerContainerId = useContext(GoogleTagManagerContainerIdProvider);

        const submit =
            useDebouncedCallback(
                () => {
                    setIsSubmitting(true);
                    context?.onSubmit()
                        .then(
                            result =>
                            {
                                if (googleTagManagerContainerId && props.buttonId && result)
                                {
                                    (window as any).dataLayer
                                        .push({
                                            'event': 'form-submit',
                                            'buttonId': props.buttonId
                                        });
                                }
                            }
                        )
                        .finally(
                            () =>
                                setIsSubmitting(false)
                        )
                },
                [
                    props.buttonId,
                    context,
                    setIsSubmitting
                ],
                500
            )
        return <RightAlignedButtonGroup>
            <PrimaryButton
                id={props.buttonId || "_" + uuid()}
                label={
                    <LocalizedText
                        code="Generic.Send"
                        value="Versturen"
                    />
                }
                onClick={submit}
                disabled={isSubmitting}
            />
        </RightAlignedButtonGroup>;
    };

export default observer(SubmitButtonViewer);
