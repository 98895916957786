import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityType } from '../../../../../../../../../@Api/Model/Implementation/EntityType';
import ViewGroup from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CardInset from '../../../../../../../../../@Future/Component/Generic/Card/CardInset';
import FunctionContext from '../../../../../../../../../@Api/Automation/Function/FunctionContext';
import { IObservableArray, runInAction } from 'mobx';
import CompositeTrigger from '../../../../../../../../../@Api/Automation/Trigger/CompositeTrigger';
import CompositeTriggerElement from '../../../../../../../../../@Api/Automation/Trigger/CompositeTriggerElement';
import uuid from '../../../../../../../../../@Util/Id/uuid';
import CompositeTriggerElementEditor from './CompositeTriggerElementEditor';
import TriggerEditor from '../TriggerEditor';
import useSwitch from '../../../../../../../../../@Util/Switch/useSwitch';
import HoverCardBottom from '../../../../../../../../../@Future/Component/Generic/Card/HoverCardBottom/HoverCardBottom';
import useDividerGlue from '../../../../../../../../../@Future/Component/Generic/ViewGroup/Api/useDividerGlue';
import MutationTrigger from '../../../../../../../../../@Api/Automation/Trigger/MutationTrigger';
import LocalizedText from '../../../../../../../Localization/LocalizedText/LocalizedText';

export interface CompositeTriggerProps
{
    entityType: EntityType;
    trigger: CompositeTrigger;
    context: FunctionContext;
}

const CompositeTriggerEditor: React.FC<CompositeTriggerProps> =
    props =>
    {
        const [ isConstructorOpen, openConstructor, closeConstructor ] = useSwitch(false);

        const constructTrigger =
            useCallback(
                (trigger: MutationTrigger) =>
                    runInAction(
                        () =>
                        {
                            props.trigger.triggers.push(
                                new CompositeTriggerElement(
                                    uuid(),
                                    trigger));

                            closeConstructor();
                        }),
                [
                    props.trigger,
                    closeConstructor
                ]);

        const deleteTrigger =
            useCallback(
                (trigger: CompositeTriggerElement) =>
                    runInAction(
                        () =>
                            (props.trigger.triggers as IObservableArray).remove(trigger)),
                [
                    props.trigger
                ]);

        const dividerGlue = useDividerGlue();

        return <>
            <CardInset>
                <LocalizedText
                    code="Automation.TriggerDescriptor"
                    value="Trigger gaat af indien één van de volgende triggers afgaat:"
                />
            </CardInset>
            <ViewGroup
                orientation="vertical"
                spacing={0}
                glue={dividerGlue}
            >
                <ViewGroupItem>
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                        glue={dividerGlue}
                    >
                        {
                            props.trigger.triggers.map(
                                (trigger, idx) =>
                                    <ViewGroupItem
                                        key={trigger.id}
                                    >
                                        <CompositeTriggerElementEditor
                                            entityType={props.entityType}
                                            trigger={trigger}
                                            context={props.context}
                                            idx={idx}
                                            onDelete={deleteTrigger}
                                        />
                                    </ViewGroupItem>)
                        }
                    </ViewGroup>
                </ViewGroupItem>
                {
                    isConstructorOpen &&
                        <ViewGroupItem>
                            <TriggerEditor
                                entityType={props.entityType}
                                context={props.context}
                                onCreate={constructTrigger}
                                onlyAllowMutationTriggers
                            />
                        </ViewGroupItem>
                }
                {
                    !isConstructorOpen &&
                        <ViewGroupItem>
                            <HoverCardBottom
                                onClick={openConstructor}
                            >
                                <LocalizedText
                                    code="Automation.AddTriggerButton"
                                    value="+ Trigger toevoegen"
                                />
                            </HoverCardBottom>
                        </ViewGroupItem>
                }
            </ViewGroup>
        </>;
    };

export default observer(CompositeTriggerEditor);
