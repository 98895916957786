import { Cookie } from '../../Cookie/Cookie';
import deleteAccessToken from './deleteAccessToken';
import { isInIe11AndWindows7AndIframe } from './isInIe11AndWindows7AndFramed';
import { isInMacAndSafariAndIframe } from './isInMacAndSafariAndFramed';

export async function setIdToken(
    idToken: string,
    expiresInMilliseconds?: number
): Promise<void>
{
    await deleteAccessToken();

    // Cookies are not read when using Windows 7 and IFrames,
    // so we fallback to session storage for this (this is used for passing the
    // Microsoft add-in review for using the add-in on the web version of Outlook on Windows 7)
    if (isInIe11AndWindows7AndIframe)
    {
        sessionStorage.setItem('idToken', idToken);
    }
    else if (isInMacAndSafariAndIframe)
    {
        localStorage.setItem('idToken', idToken);
    }
    else
    {
        await Cookie.set(
            'id_token',
            idToken,
            expiresInMilliseconds
        );
    }
}
