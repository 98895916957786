import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import useActiveTimeRegistration from '../Hooks/useActiveTimeRegistration';
import styles from './RecordButton.module.scss';
import useEndTimeRegistration from '../Hooks/useEndTimeRegistration';
import useTypes from '../../Type/Api/useTypes';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { observer, useComputed } from 'mobx-react-lite';
import moment from 'moment';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Icon from '../../../../../@Future/Component/Generic/Icon/Icon';
import useDialog from '../../../../../@Service/Navigation/Page/Hooks/useDialog';
import TimeRegistration from '../TimeRegistration/TimeRegistration';
import getDurationLabel from '../../../../../@Util/Date/getDurationLabel';
import useClock from '../../../../../@Util/Date/useClock';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import currentUserContext from '../../../User/CurrentUserContext';

const originalTitle = 'Tribe';

export interface RecordButtonProps
{

}

const RecordButton: React.FC<RecordButtonProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(currentUserContext);
        const activeTimeRegistration = useActiveTimeRegistration();
        const endTimeRegistration = useEndTimeRegistration();

        const canCreateTimeRegistration =
            useMemo(
                () =>
                    currentUserStore.rightProfile.canCreate(types.TimeRegistration.Type),
                [
                    currentUserStore,
                    types
                ]
            );

        const isRunning =
            useMemo(
                () =>
                !!activeTimeRegistration,
                [
                    activeTimeRegistration
                ]);

        const stopTimeRegistration =
            useCallback(
                () =>
                {
                    if (activeTimeRegistration)
                    {
                        return endTimeRegistration(
                            activeTimeRegistration,
                            new Date());
                    }

                    return Promise.resolve();
                },
                [
                    activeTimeRegistration,
                    endTimeRegistration
                ]);

        const activity =
            useComputed(
                () =>
                {
                    if (activeTimeRegistration)
                    {
                        return activeTimeRegistration.getRelatedEntityByDefinition(
                            false,
                            types.TimeRegistration.RelationshipDefinition.Activity);
                    }

                    return undefined;
                },
                [
                    activeTimeRegistration,
                    types
                ]);

        const now = useClock(activeTimeRegistration !== undefined);
        const duration =
            useComputed(
                () =>
                {
                    if (activeTimeRegistration)
                    {
                        return moment(now)
                            .diff(
                                activeTimeRegistration.getObjectValueByField(types.TimeRegistration.Field.StartDate),
                                'milliseconds');
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    activeTimeRegistration,
                    types,
                    now
                ]);

        const durationLabel =
            useMemo(
                () =>
                    getDurationLabel(duration, true),
                [
                    duration
                ]);

        useEffect(
            () =>
            {
                if (isRunning && duration > 0)
                {
                    document.title = `${durationLabel}  ·  ${originalTitle}`;
                }
                else
                {
                    document.title = originalTitle;
                }
            },
            [
                duration,
                durationLabel,
                isRunning
            ]);

        const [ openDialog ] =
            useDialog(
                close =>
                    <TimeRegistration
                        close={close}
                        timeRegistration={activeTimeRegistration}
                        hideStartDateWhenNew
                        hideEndDateWhenNew
                    />,
                [
                    activeTimeRegistration
                ]);

        if (!canCreateTimeRegistration)
        {
            return null;
        }

        return <div
            className={styles.root}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={5}
                alignment="center"
            >
                {
                    isRunning &&
                        <ViewGroupItem>
                            <div
                                className={styles.activeRegistration}
                                onClick={openDialog}
                            >
                                <div
                                    className={styles.icon}
                                >
                                    <Icon
                                        icon="access_time"
                                        size={20}
                                    />
                                </div>
                                {
                                    activity &&
                                        <div
                                            className={styles.activity}
                                        >
                                            {activity.name}
                                        </div>
                                }
                                <div
                                    className={styles.duration}
                                >
                                    ·&nbsp;&nbsp;{durationLabel}
                                </div>
                            </div>
                        </ViewGroupItem>
                }
                <ViewGroupItem>
                    {
                        isRunning
                            ?
                                <IconButton
                                    icon="timer_off"
                                    tooltip={
                                        <LocalizedText
                                            code="TimeRegistration.Stop"
                                            value="Urenregistratie stoppen"
                                        />
                                    }
                                    onClick={stopTimeRegistration}
                                    iconSize={22}
                                    color="white"
                                />
                            :
                                <IconButton
                                    icon="timer"
                                    tooltip={
                                        <LocalizedText
                                            code="TimeRegistration.Start"
                                            value="Urenregistratie starten"
                                        />
                                    }
                                    onClick={openDialog}
                                    iconSize={22}
                                    color="white"
                                />
                    }
                </ViewGroupItem>
            </ViewGroup>
        </div>;
    };

export default observer(RecordButton);
