import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import GoogleMapReact from 'google-map-react';
import {GoogleMapsPin} from './GoogleMapsPin';
import {makeStyles} from '@material-ui/styles';
import {useComputed} from 'mobx-react-lite';
import {GoogleMapsPopup} from './GoogleMapsPopup';
import {borderColor} from '../../../@Resource/Theme/Theme';
import getTypes from '../../Domain/Entity/Type/Api/getTypes';
import getConnectorActivationByCode from '../../../@Api/Entity/Bespoke/Connector/getConnectorActivationByCode';
import {Entity} from '../../../@Api/Model/Implementation/Entity';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {GoogleMapsLabel} from './GoogleMapsLabel';

export interface GoogleMapLocation
{
    lat: number;
    lng: number;
    label?: string;
    link?: string;
    entity?: Entity;
    addressProvider?: Entity;
    address?: Entity;
}

export interface GoogleMapViewProperties
{
    fullscreenControl?: boolean;
    keyboardShortcuts?: boolean;
    mapTypeControl?: boolean;
    panControl?: boolean;
    rotateControl?: boolean;
    scrollwheel?: boolean;
    streetviewControl?: boolean;
    zoomControl?: boolean;
    cameraControl?: boolean;
}

export interface GoogleMapProps
{
    height: number;
    locations: GoogleMapLocation[];
    label?: string;
    zoomLevel: any;
    viewProperties?: GoogleMapViewProperties;
    useClusterer?: boolean;
    onClickMarker?: (any) => void;
    onBoundsChange?: (LatLngBounds) => void;
}

const defaultMapProps : GoogleMapViewProperties =
{
    fullscreenControl: false,
    keyboardShortcuts: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    scrollwheel: true,
    streetviewControl: false,
    zoomControl: false,
    cameraControl: false
}

const useStyles =
    makeStyles({
        map: {
            width: 150,
            minWidth: '100%',
            border: `1px solid ${borderColor}`,
        }
    });

export const GoogleMap: React.FC<GoogleMapProps> = observer(
    ({
        height,
        locations,
        zoomLevel,
        viewProperties,
        useClusterer,
        onClickMarker,
        onBoundsChange
}) =>
{
    const types = getTypes();
    const [ configuration, setConfiguration] = useState<any>(undefined);
    const [ connectorActive, setConnectorActive] = useState<boolean>(false);
    const [ mapLoaded, setMapLoaded] = useState<boolean>(false);
    const classes = useStyles();
    const [ popupLocation, setPopupLocation ] = useState<GoogleMapLocation>(undefined);
    const [ labelLocation, setLabelLocation ] = useState<GoogleMapLocation>(undefined);

    useEffect(
        () =>
        {
            getConnectorActivationByCode('GoogleMaps')
                .then(
                    activation =>
                    {
                        if (activation)
                        {
                            setConfiguration(activation.getObjectValueByField(types.ConnectorActivation.Field.Configuration));
                            setConnectorActive(activation.getObjectValueByField(types.ConnectorActivation.Field.IsActivated));
                        }
                        else
                        {
                            setConfiguration(undefined);
                            setConnectorActive(false);
                        }
                    }
                )
        },
        [
            types,
            setConfiguration,
            setConnectorActive
        ]
    );

    const getBoundsWithAllLocations =
        useCallback(
            (map, maps) =>
            {
                const bounds = new maps.LatLngBounds();

                locations.forEach(
                    location => {
                        bounds.extend(
                            new maps.LatLng(
                                location.lat,
                                location.lng,
                            )
                        );
                    }
                );
                return bounds;
            },
            [
                locations
            ]
        );

    const onGoogleApiLoad =
        useCallback(
        ({
               map,
               maps,
        }) =>
        {
            setMapLoaded(true);

            maps.event.addListenerOnce(map, 'idle',
                () =>
                {
                    if (locations.length > 1)
                    {
                        const bounds = getBoundsWithAllLocations(map, maps);
                        map.fitBounds(bounds, 100);
                    }
                    if (map.getZoom() > 12)
                    {
                        map.setZoom(12);
                    }
                }
            );

            if (useClusterer)
            {
                const markers =
                    locations.map(
                        (location) =>
                        {
                            const marker = new maps.Marker({
                                position: {
                                    lat: location.lat,
                                    lng: location.lng,
                                },
                            });

                            if (onClickMarker)
                            {
                                // Add a click listener for each marker, and set up the info window.
                                marker.addListener('click',
                                    () => {
                                        onClickMarker(location);
                                        setPopupLocation(location);
                                        setLabelLocation(undefined)
                                    }
                                );

                                marker.addListener('mouseover',
                                    () => {
                                        setLabelLocation(location);
                                    }
                                );
                            }
                            return marker;
                        }
                    );

                new MarkerClusterer({
                    map,
                    markers,
                });
            }
        },
        [
            setMapLoaded,
            locations,
            getBoundsWithAllLocations
        ]
    );

    const createMapOptions =
        useComputed(
            () =>
            {
                return {
                    ...defaultMapProps,
                    ...viewProperties
                    }
            },
            [
                defaultMapProps,
                viewProperties
            ]
        );

    const childClick =
        useCallback(
            key =>
            {
                setPopupLocation(locations[key])
            },
            [
                locations,
                setPopupLocation
            ]
        );

    const boundsChange =
        useCallback(
            (_center, _zoom, bounds) =>
            {
                if (onBoundsChange)
                {
                    onBoundsChange(bounds);
                }
            },
            []
        );

    if (connectorActive && locations?.length > 0)
    {
        return <div
            className={classes.map}
            style={{
                height: height
            }}
        >
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: configuration.apikey,
                }}
                defaultCenter={locations[0]}
                defaultZoom={zoomLevel}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onGoogleApiLoad}
                options={createMapOptions}
                onChildClick={childClick}
                onClick={() => setPopupLocation(undefined)}
                onBoundsChange={boundsChange}
            >
                {
                   !useClusterer && mapLoaded && locations
                        .map(
                            location =>
                                <GoogleMapsPin
                                    lat={location.lat}
                                    lng={location.lng}
                                    label={location.label}
                                />
                        )
                }
                {
                    !useClusterer && popupLocation &&
                    <GoogleMapsPopup
                        lat={popupLocation.lat}
                        lng={popupLocation.lng}
                        location={popupLocation}
                    />
                }
                {
                    useClusterer && labelLocation &&
                    <GoogleMapsLabel
                        lat={labelLocation.lat}
                        lng={labelLocation.lng}
                        location={labelLocation}
                    />
                }
            </GoogleMapReact>
        </div>
    }
    else
    {
        return null;
    }

});
