import React, { useCallback, useEffect, useMemo } from 'react';
import { Entity } from '../../../../../../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import useTypes from '../../../../../../../../Type/Api/useTypes';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from '../../../../../../../../Input/Input';
import MenuButton from '../../../../../../../../Item/MenuButton/MenuButton';
import Checkbox from '../../../../../../../../../../../@Future/Component/Generic/Input/Checkbox/Checkbox';
import { computed } from 'mobx';
import { EntityExpansionBuilder } from '../../../../../../../../Selection/Builder/EntityExpansionBuilder';
import { EntityPath } from '../../../../../../../../Path/@Model/EntityPath';
import useEntityValue from '../../../../../../../../../../../@Api/Entity/Hooks/useEntityValue';
import ButtonGroup from '../../../../../../../../../../../@Future/Component/Generic/Button/ButtonGroup/ButtonGroup';
import useToggle from '../../../../../../../../../../../@Util/Toggle/useToggle';
import NoteIconButton from '../../../../../../../../../../../@Future/Component/Generic/Button/Variant/NoteIconButton/NoteIconButton';
import { primaryColor, textSecondaryColor } from '../../../../../../../../../../../@Resource/Theme/Theme';
import EntityLink from '../../../../../../../../Link/EntityLink';
import { CommitContext } from '../../../../../../../../../../../@Api/Entity/Commit/Context/CommitContext';

export interface MileageRegistrationProps
{
    entity: Entity;
    onSelect?: (entity: Entity, isSelected: boolean) => void;
    selected?: boolean;
    milestone?: Entity;
    showActivity?: boolean;
    showBillingActivity?: boolean;
    canHaveMilestone?: boolean;
    onDelete?: () => void;
    commitContext: CommitContext;
}

const MileageRegistration: React.FC<MileageRegistrationProps> =
    props =>
    {
        const types = useTypes();

        const isProcessed =
            useEntityValue(
                props.entity,
                types.MileageRegistration.Field.IsProcessed,
                undefined,
                props.commitContext
            );
        const isBilled =
            useComputed(
                () =>
                    props.entity.hasRelationshipsByDefinition(
                        true,
                        types.ProductLine.RelationshipDefinition.BilledMileageRegistrations,
                        props.commitContext
                    ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);
        const onSelect =
            useCallback(
                () =>
                    props.onSelect(props.entity, !props.selected),
                [
                    props.onSelect,
                    props.entity,
                    props.selected
                ]);

        useEffect(
            () =>
                computed(
                    () =>
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            types.MileageRegistration.RelationshipDefinition.Type,
                            props.commitContext
                        )
                )
                    .observe(
                        change =>
                        {
                            if (change.newValue)
                            {
                                new EntityExpansionBuilder(
                                    change.newValue.entityType,
                                    [
                                        change.newValue
                                    ],
                                    [
                                        EntityPath.fromEntity(change.newValue)
                                            .joinTo(
                                                types.MileageRegistrationType.RelationshipDefinition.Product,
                                                false)
                                    ])
                                    .expand();
                            }
                        }),
            [
                props.entity,
                types,
                props.commitContext,
            ]);

        const isBillableAllowed =
            useComputed(
                () =>
                    props.entity.getRelatedEntitiesByDefinition(
                        false,
                        types.MileageRegistration.RelationshipDefinition.Type,
                        props.commitContext
                    )
                        .some(
                            activity =>
                                activity.hasRelationshipsByDefinition(
                                    false,
                                    types.MileageRegistrationType.RelationshipDefinition.Product,
                                    props.commitContext
                                )
                        ),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const note =
            useEntityValue(
                props.entity,
                types.MileageRegistration.Field.Description,
                undefined,
                props.commitContext
            );
        const [ isNoteOpen, toggleNote ] = useToggle(note !== undefined);

        const showMilestone =
            useMemo(
                () =>
                    props.canHaveMilestone && !props.milestone,
                [
                    props.canHaveMilestone,
                    props.milestone
                ]);

        const billingActivity =
            useComputed(
                () =>
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.ProductLine.RelationshipDefinition.BilledMileageRegistrations,
                            true)
                        .joinTo(
                            types.ProductLine.RelationshipDefinition.InvoiceProductLine,
                            false)
                        .joinTo(
                            types.Activity.RelationshipDefinition.ProductLines,
                            true)
                        .traverseEntity(
                            props.entity,
                            props.commitContext
                        )
                        .find(() => true),
                [
                    props.entity,
                    types,
                    props.commitContext,
                ]);

        const colSpan =
            useMemo(
                () =>
                {
                    let base = 6;

                    if (props.onSelect)
                    {
                        base += 1;
                    }

                    if (props.showActivity)
                    {
                        base += 1;
                    }

                    if (showMilestone)
                    {
                        base += 1;
                    }

                    return base;
                },
                [
                    props.onSelect,
                    props.showActivity,
                    showMilestone
                ]);

       return <>
            <TableRow>
                {
                    props.onSelect &&
                        <TableCell>
                            <Checkbox
                                checked={props.selected}
                                onToggle={onSelect}
                                disabled={isBilled}
                            />
                        </TableCell>
                }
                {
                    props.showActivity &&
                        <TableCell>
                            <Input
                                entity={props.entity}
                                field={types.Activity.RelationshipDefinition.MileageRegistrations}
                                parent
                                labelPosition="none"
                                autoFocus={props.entity.isNew()}
                                disabled={isProcessed}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                }
                <TableCell>
                    <Input
                        entity={props.entity}
                        field={types.Relationship.Person.Contact.Employee.RelationshipDefinition.MileageRegistrations}
                        parent
                        labelPosition="none"
                        disabled={isProcessed}
                        commitContext={props.commitContext}
                    />
                </TableCell>
                <TableCell>
                    <Input
                        entity={props.entity}
                        field={types.MileageRegistration.Field.Date}
                        labelPosition="none"
                        disabled={isProcessed}
                        commitContext={props.commitContext}
                    />
                </TableCell>
                <TableCell>
                    <Input
                        entity={props.entity}
                        field={types.MileageRegistration.RelationshipDefinition.Type}
                        labelPosition="none"
                        autoFocus={props.entity.isNew()}
                        disabled={isProcessed}
                        commitContext={props.commitContext}
                    />
                </TableCell>
                <TableCell>
                    <Input
                        entity={props.entity}
                        field={types.MileageRegistration.Field.Distance}
                        labelPosition="none"
                        disabled={isProcessed}
                        commitContext={props.commitContext}
                    />
                </TableCell>
                {
                    showMilestone &&
                        <TableCell>
                            <Input
                                entity={props.entity}
                                field={types.Milestone.RelationshipDefinition.MileageRegistrations}
                                parent
                                labelPosition="none"
                                disabled={isProcessed}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                }
                <TableCell
                    align="center"
                >
                    {
                        isBillableAllowed &&
                            <Input
                                entity={props.entity}
                                field={types.MileageRegistration.Field.IsBillable}
                                labelPosition="none"
                                disabled={isBilled}
                                commitContext={props.commitContext}
                            />
                    }
                </TableCell>
                {
                    props.showBillingActivity &&
                        <TableCell>
                            {
                                billingActivity &&
                                    <EntityLink
                                        entity={billingActivity}
                                    />
                            }
                        </TableCell>
                }
                <TableCell>
                    <ButtonGroup
                        noWrap
                    >
                        <NoteIconButton
                            onClick={toggleNote}
                            color={isNoteOpen ? primaryColor : textSecondaryColor}
                        />
                        <MenuButton
                            entity={props.entity}
                            onDelete={props.onDelete}
                        >
                        </MenuButton>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
            {
                isNoteOpen &&
                    <TableRow>
                        <TableCell
                            colSpan={colSpan}
                        >
                            <Input
                                entity={props.entity}
                                field={types.MileageRegistration.Field.Description}
                                labelPosition="none"
                                doAutocommit={!props.entity.isNew()}
                                autoFocus={note === undefined}
                                commitContext={props.commitContext}
                            />
                        </TableCell>
                    </TableRow>
            }
        </>;
    };

export default observer(MileageRegistration);
