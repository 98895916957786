import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { DataObjectEditorStore } from '../../../DataObject/Editor/Value/Editor/DataObjectEditorStore';
import { DataObjectRepresentation } from '../../../DataObject/Model/DataObjectRepresentation';
import { EmailType } from '../../../DataObject/Type/Text/Email/EmailType';
import { computed, reaction, runInAction } from 'mobx';
import { getModel } from '../../../../../@Util/TransactionalModelV2/index';
import { DataObject } from '../../../DataObject/Model/DataObject';
import { EntityContext } from '../../@Model/EntityContext';
import { EntityPath } from '../../Path/@Model/EntityPath';
import { EntityFieldCondition } from '../../../../../@Api/Model/Implementation/EntityFieldCondition';
import { DataObjectSpecification } from '../../../DataObject/Model/DataObjectSpecification';
import { FormHandlerContext } from '../../../../Generic/Form/FormHandlerContext';
import { FormEvent } from '../../../../Generic/Form/FormEvent';
import { FormEventListener } from '../../../../Generic/Form/FormEventListener';
import InputFocus from '../../../Multiplayer/InputFocus/InputFocus';
import { DataObjectEditor } from '../../../DataObject/Editor/Value/Editor/DataObjectEditor';
import EntityTypeContext from '../../Type/EntityTypeContext';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import CurrentUserContext from '../../../User/CurrentUserContext';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { primaryColor } from '../../../../../@Resource/Theme/Theme';
import isFieldInEntityValid from '../../../../../@Api/Entity/Validation/isFieldInEntityValid';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import { commitEntityWithContext } from '../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { ValueEditorProps } from './ValueEditor';
import { EntityValue } from '../../../../../@Api/Model/Implementation/EntityValue';
import FieldView from '../../Field/View/FieldView';
import { Comparator } from '../../../DataObject/Model/Comparator';
import { CommittableTouchField } from '../../../../../@Future/Component/Generic/CommittableTouchField/CommittableTouchField';
import useTypes from '../../Type/Api/useTypes';
import openWindow from '../../../../../@Util/Window/openWindow';

export interface InternalValueEditorProps extends ValueEditorProps
{
    value: EntityValue;
}

const InternalValueEditor: React.FC<InternalValueEditorProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const currentUserStore = useContext(CurrentUserContext);

        const value = props.value;

        // Unsure if necessary
        useEffect(
            () =>
            {
                props.entity.setManaged(true);
            },
            [
                props.entity
            ]);

        const representation =
            useComputed(
                () =>
                    new DataObjectRepresentation({
                        ...props.field.getRepresentation(props.entity).data,
                        ...props.representation,
                    }),
                [
                    props.field,
                    props.entity,
                    entityTypeStore,
                    props.representation
                ]);

        const canCreateEmail =
            useMemo(
                () =>
                    currentUserStore.rightProfile.canCreate(types.Activity.Email.Type),
                [
                    currentUserStore,
                    types,
                ]
            );

        const hasEmailComposer =
            useComputed(
                () =>
                    value.dataObject.specification.type instanceof EmailType &&
                    props.entity.hasValueForField(props.field) &&
                    isFieldInEntityValid(props.entity, props.field),
                [
                    value,
                    props.entity,
                    props.field
                ]);

        const openEmailComposer =
            useCallback(
                () =>
                    canCreateEmail
                        ? entityTypeStore.entity.activity.email.openEmailComposerForRecipient(value)
                        : openWindow(`mailto:${value.dataObject?.value}`),
                [
                    canCreateEmail,
                    value,
                    entityTypeStore
                ]);

        const isRequired =
            useComputed(
                () =>
                    props.field.dataObjectSpecification.isRequired
                    || props.isRequiredOverride,
                [
                    props.field,
                    props.isRequiredOverride
                ]);

        const context =
            useComputed(
                () => ({
                    entityContext:
                        new EntityContext([
                                props.entity
                            ],
                            EntityPath.root(value.field.entityType)),
                    commitContext: props.commitContext,
                }),
                [
                    props.entity,
                    value,
                    props.commitContext,
                ]);

        const computationResult =
            useComputed(
                () =>
                {
                    if (props.field.initializedComputation)
                    {
                        return props.field.initializedComputation.compute(context);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.field,
                    context
                ]);

        const defaultValueComputationResult =
            useComputed(
                () =>
                {
                    if (props.field.initializedDefaultValueComputation)
                    {
                        return props.field.initializedDefaultValueComputation.compute(context);
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    props.field,
                    context
                ]);

        const validConditions =
            useComputed<EntityFieldCondition[]>(
                () => props.field.conditions.filter(condition => condition.initializedPredicate.evaluate(context)),
                [
                    props.field,
                    context
                ]);

        const isReadonly =
            useComputed<boolean>(
                () =>
                {
                    if (!value)
                    {
                        return false;
                    }

                    return !value.isEditable(entityTypeStore)
                        || validConditions.some(condition => condition.isReadonly === true)
                        || !currentUserStore.rightProfile.canUpdate(props.entity, props.commitContext);
                },
                [
                    value,
                    entityTypeStore,
                    validConditions,
                    currentUserStore,
                    props.entity,
                    props.commitContext,
                ]);

        const specification =
            useComputed<DataObjectSpecification>(
                () =>
                {
                    if (validConditions.some(condition => condition.initializedSpecification != null))
                    {
                        return validConditions.filter(condition => condition.initializedSpecification)[0].initializedSpecification;
                    }
                    else if (props.field === entityTypeStore.typeField)
                    {
                        const switchableType = props.entity.entityType.getSwitchableType();

                        return new DataObjectSpecification(
                            props.field.dataObjectSpecification.type,
                            {
                                ...props.field.dataObjectSpecification.data,
                                rootEntityTypeId: switchableType ? switchableType.id : props.entity.entityType.id,
                                isInstantiable: true,
                            },
                            isRequired);
                    }
                    else
                    {
                        return props.field.dataObjectSpecification;
                    }
                },
                [
                    validConditions,
                    props.field,
                    entityTypeStore,
                    props.entity,
                    props.field,
                    isRequired,
                ]);

        const defaultValue =
            useComputed<DataObject>(
                () =>
                {
                    if (props.field.initializedDefaultValueComputation)
                    {
                        return props.field.initializedDefaultValueComputation.compute(context);
                    }
                    else
                    {
                        return null;
                    }
                },
                [
                    props.field,
                    context
                ]);

        const commitContextDataObject =
            useComputed(
                () =>
                    props.commitContext?.getValue(props.entity, props.field),
                [
                    props.commitContext,
                    props.entity,
                    props.field,
                ]
            );
        const valueDataObject =
            useComputed(
                () =>
                    value.dataObject,
                [
                    value
                ]
            );
        const [ dataObject, setDataObject ] =
            useState<DataObject>(
                () =>
                    (commitContextDataObject ?? valueDataObject).clone()
            );

        useEffect(
            () =>
            {
                const newValue = (commitContextDataObject ?? valueDataObject);

                setDataObject(
                    oldValue =>
                    {
                        if (DataObject.compare(oldValue, newValue, Comparator.Equals))
                        {
                            return oldValue;
                        }
                        else
                        {
                            return newValue.clone();
                        }
                    }
                );
            },
            [
                commitContextDataObject,
                valueDataObject,
                valueDataObject.value, // when the original model changes, the edited value should change as well (we need this line for that)
                setDataObject,
            ]
        );

        const setSpecification =
            useCallback(
                (specification: DataObjectSpecification) =>
                    runInAction(
                        () =>
                            dataObject.specification = specification),
                [
                    dataObject
                ]);

        const setValue =
            useCallback(
                (value: any) =>
                    runInAction(
                        () =>
                        {
                            props.entity.setValueByField(
                                props.field,
                                value,
                                undefined,
                                undefined,
                                props.commitContext
                            );

                            if (props.onChange)
                            {
                                props.onChange(value);
                            }
                        }
                    ),
                [
                    props.entity,
                    props.field,
                    value,
                    props.commitContext,
                    props.onChange,
                ]);

        // const setValueByField =
        //     useCallback(
        //         (field: EntityField, value: any) =>
        //             runInAction(
        //                 () =>
        //                     props.entity.setValueByField(field, value)),
        //         [
        //             props.entity
        //         ]);

        const checkAndDoResetValueToDefault =
            useCallback(
                () =>
                {
                    if (isRequired && defaultValue != null && value.isEmpty)
                    {
                        setValue(defaultValue.value);
                    }
                },
                [
                    isRequired,
                    defaultValue,
                    value,
                    setValue
                ]);

        const checkAndDoCommit =
            useCallback(
                (doDebounceCommit: boolean = false) =>
                {
                    return commitEntityWithContext(
                        props.entity,
                        props.commitContext,
                        {
                            isDebounced: doDebounceCommit,
                            isDeferred: true,
                            isAutoCommit: true,
                        }
                    );
                },
                [
                    props.entity,
                    props.commitContext,
                ]);

        const [isFocused, setFocused] = useState<boolean>(false);
        const focus =
            useCallback(
                () =>
                    runInAction(
                        () =>
                        {
                            setFocused(true);
                            props.entity.setFocused(true);
                        }
                    ),
                [
                    props.entity,
                ]);

        const setDataObjectValue =
            useCallback(
                (value: DataObject) =>
                {
                    // This ensures that in case of static fields, the static field in the entity is updated as well
                    // Similarly, the name is updated as well
                    if (props.commitContext)
                    {
                        props.commitContext.setValue(
                            props.entity,
                            props.field,
                            value
                        );
                    }
                    else
                    {
                        props.entity.setValueByField(
                            props.field,
                            value.value);
                    }

                    if (props.onChange)
                    {
                        props.onChange(value.value);
                    }
                },
                [
                    props.commitContext,
                    props.entity,
                    props.field,
                    props.onChange,
                ]
            );

        const blur =
            useCallback(
                (dataObject?: DataObject, doDebounceCommit: boolean = false) =>
                    runInAction(
                        () =>
                        {
                            setFocused(false);

                            if (dataObject)
                            {
                                setDataObjectValue(dataObject);
                            }

                            // If field is required, check and reset to default value
                            if (isRequired && value.field.defaultValueComputation)
                            {
                                checkAndDoResetValueToDefault();
                            }

                            if (props.onBlur)
                            {
                                props.onBlur();
                            }

                            props.entity.setFocused(false);

                            if (props.doAutoCommit)
                            {
                                return checkAndDoCommit(doDebounceCommit);
                            }
                        }),
                [
                    setDataObjectValue,
                    value,
                    isRequired,
                    checkAndDoResetValueToDefault,
                    props.onBlur,
                    props.entity,
                    props.doAutoCommit,
                    checkAndDoCommit,
                ]);

        const editorStore =
            useMemo(
                () =>
                {
                    const handlerContext =
                        new FormHandlerContext<DataObject>(
                            props.handlerContext
                                ?
                                props.handlerContext.listeners.slice()
                                :
                                []);

                    handlerContext.listen(new FormEventListener<DataObject>(FormEvent.KeyUp, event =>
                    {
                        if (props.onKeyUp)
                        {
                            props.onKeyUp(event);
                        }
                    }));

                    handlerContext.listen(new FormEventListener<DataObject>(FormEvent.Change, event =>
                    {
                        if (!dataObject.specification.type.hasBlurEvent(dataObject))
                        {
                            blur(event.data);
                        }
                    }));

                    handlerContext.listen(new FormEventListener<DataObject>(FormEvent.Focus, () =>
                    {
                        focus();
                    }));

                    handlerContext.listen(new FormEventListener<DataObject>(FormEvent.Blur, event =>
                    {
                        blur(event.data);
                    }));

                    return new DataObjectEditorStore({
                        dataObject: dataObject,
                        onChange:
                            value =>
                                setDataObjectValue(value),
                        isDisabled: false,
                        handlerContext: handlerContext,
                        isFocused: props.isFocused,
                        hasUnderline: store => props.hasUnderline || (!dataObject.isValid && store.isTouched),
                        alignment: props.alignment,
                        inputStyle: props.inputStyle,
                        onEnterPressed: props.onEnterPressed,
                        representation: representation,
                        placeholder: props.placeholder
                    });
                },
                [
                    props.handlerContext,
                    props.onKeyUp,
                    blur,
                    focus,
                    props.entity,
                    props.field,
                    props.commitContext,
                    dataObject,
                    setDataObjectValue,
                    props.isFocused,
                    props.hasUnderline,
                    props.alignment,
                    props.inputStyle,
                    props.onEnterPressed,
                    representation,
                    props.placeholder
                ]);

        // const setFocused =
        //     useCallback(
        //         (isFocused: boolean) =>
        //             runInAction(
        //                 () =>
        //                 {
        //                     props.isFocused = isFocused;
        //                     editorStore.setFocused(isFocused);
        //                 }),
        //         [
        //             props.isFocused,
        //             editorStore
        //         ]);
        //
        // const touch =
        //     useCallback(
        //         () =>
        //             runInAction(
        //                 () =>
        //                 {
        //                     if (editorStore)
        //                     {
        //                         editorStore.touch();
        //                     }
        //                 }),
        //         [
        //             editorStore
        //         ]);

        useEffect(
            () =>
            {
                if (value.isNew && defaultValueComputationResult)
                {
                    setValue(defaultValueComputationResult.value);
                }
            },
            [
                value,
                setValue
            ]);

        useEffect(
            () =>
            {
                if (props.field.isComputedField)
                {
                    return computed(
                        () =>
                            getModel(value.dataObject).value)
                        .observe(
                            change =>
                                setValue(change.newValue));
                }
            },
            [
                props.field,
                setValue
            ]);

        useEffect(
            () =>
            {
                // TODO: this causes an infinite loop
                // Reaction for final specification
                return reaction(
                    () =>
                        specification,
                    () =>
                        setSpecification(specification),
                    {
                        fireImmediately: true
                    });
            },
            [
                specification,
                setSpecification
            ]);

        useEffect(
            () =>
            {
                if (value && value.field.initializedComputation)
                {
                    return computed(
                        () =>
                            computationResult)
                        .observe(
                            change =>
                                setValue(change.newValue ? change.newValue.value : undefined));
                }
            },
            [
                value,
                value.field
            ]);

        useEffect(
            () =>
            {
                return () =>
                {
                    blur(undefined, true);
                }
            },
            [
                blur
            ]);

        return <CommittableTouchField
            focused={isFocused}
            showCommitButtonOnTouch={props.doAutoCommit || props.showCommitButtonOnTouch}
            onCommit={blur}
        >
            <ViewGroup
                orientation="horizontal"
                spacing={10}
                alignment="center"
            >
                <ViewGroupItem
                    ratio={1}
                >
                    {
                        props.isInEditMode && !isReadonly
                            ? <div
                                onClick={(e) => e.stopPropagation ? e.stopPropagation() : undefined}
                            >
                                <InputFocus
                                    entity={props.entity}
                                    field={props.field}
                                    focused={editorStore.isFocused}
                                    value={value.dataObject.value}
                                    onChange={setValue}
                                >
                                    <DataObjectEditor
                                        store={editorStore}
                                    />
                                </InputFocus>
                            </div>
                            : <FieldView
                                entity={props.entity}
                                field={props.field}
                                commitContext={props.commitContext}
                            />
                    }
                </ViewGroupItem>
                {
                    hasEmailComposer &&
                    <ViewGroupItem>
                        <IconButton
                            icon="email"
                            color={primaryColor}
                            onClick={openEmailComposer}
                            tooltip={
                                <LocalizedText
                                    code="Generic.SendEmail"
                                    value="E-mail versturen"
                                />
                            }
                            size={26}
                            iconSize={16}
                        />
                    </ViewGroupItem>
                }
            </ViewGroup>
        </CommittableTouchField>;
    };

export default observer(InternalValueEditor);
