import * as React from 'react';
import { FormEvent } from '../../../../../Generic/Form/FormEvent';
import { FormHandlerProperties } from '../../../../../Generic/Form/FormHandlerProperties';
import { DataObjectBespokeEditor } from '../../../Editor/Value/Bespoke/DataObjectBespokeEditor';
import { DateRangeValue } from './DateRangeValue';
import { DataObjectEditorStore } from '../../../Editor/Value/Editor/DataObjectEditorStore';
import { observer } from 'mobx-react';
import { DateRangeEditorStore } from './DateRangeEditorStore';
import StaticSelectbox from '../../../../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import DateEditor from '../../../../../../@Future/Component/Generic/Input/DateEditor/DateEditor';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import DateTimeEditor from '../../../../../../@Future/Component/Generic/Input/DateTimeEditor/DateTimeEditor';
import { startOfDay } from 'date-fns';
import getDatesFromRange from './Api/getDatesFromRange';
import LocalizedText from "../../../../Localization/LocalizedText/LocalizedText";

@observer
export class DateRangeEditor extends DataObjectBespokeEditor<DateRangeEditorStore>
{
    renderComponent(store: DataObjectEditorStore<DateRangeEditorStore>)
    {
        let currentValue = store.dataObject.getValue() as DateRangeValue;
        let typeHandlers = new FormHandlerProperties(store.handlerContext, store.dataObject);

        // Type change handlers
        typeHandlers.wrap(FormEvent.Change, (event) =>
        {
            if (event)
            {
                let value = Object.assign({}, currentValue, { type: event.value });

                [value.from, value.to] = getDatesFromRange(value);

                store.dataObject.setValue(value);
            }
            else
            {
                store.dataObject.setValue(undefined);
            }
        });

        if (currentValue)
        {
            if (!currentValue.type || currentValue.type === 'DateRange' || currentValue.type === 'DateTimeRange')
            {
                return this.renderRangePicker();
            }
            else if (currentValue.type === 'Year')
            {
                return this.renderYearPicker();
            }
            else if (currentValue.type === 'Quarter')
            {
                return this.renderQuarterPicker();
            }
            else if (currentValue.type === 'Month')
            {
                return this.renderMonthPicker();
            }
        }

        return this.renderTypeField();
    }

    renderRangePicker()
    {
        const isTime = this.store.dataObject.value.type === 'DateTimeRange';
        const [fromDate, toDate] = getDatesFromRange(this.store.dataObject.value);

        const onChangeFrom =
            (date: Date) =>
                this.store.changeValue({
                    ...this.store.dataObject.value,
                    from: isTime ? date : startOfDay(date)
                });

        const onChangeTo =
            (date: Date) =>
                this.store.changeValue({
                    ...this.store.dataObject.value,
                    to: isTime ? date : startOfDay(date)
                });

        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                {this.renderTypeField()}
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.From"
                            value="Van"
                        />
                    }
                    labelPosition="left"
                    inline
                >
                    {
                        isTime
                            ?
                                <DateTimeEditor
                                    value={fromDate}
                                    onChange={onChangeFrom}
                                />
                            :
                                <DateEditor
                                    value={fromDate}
                                    onChange={onChangeFrom}
                                />
                    }
                </Input>
            </ViewGroupItem>
            <ViewGroupItem>
                <Input
                    label={
                        <LocalizedText
                            code="Generic.ToDate"
                            value="Tot"
                        />
                    }
                    labelPosition="left"
                    inline
                >
                    {
                        isTime
                            ?
                                <DateTimeEditor
                                    value={toDate}
                                    onChange={onChangeTo}
                                />
                            :
                                <DateEditor
                                    value={toDate}
                                    onChange={onChangeTo}
                                />
                    }
                </Input>
            </ViewGroupItem>
        </ViewGroup>;
    }

    renderYearPicker()
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                {this.renderTypeField()}
            </ViewGroupItem>
            <ViewGroupItem>
                {this.renderYearField()}
            </ViewGroupItem>
        </ViewGroup>;
    }

    renderQuarterPicker()
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                {this.renderTypeField()}
            </ViewGroupItem>
            <ViewGroupItem>
                {this.renderYearField()}
            </ViewGroupItem>
            <ViewGroupItem>
                {this.renderQuarterField()}
            </ViewGroupItem>
        </ViewGroup>;
    }

    renderMonthPicker()
    {
        return <ViewGroup
            orientation="horizontal"
            spacing={15}
            alignment="center"
        >
            <ViewGroupItem>
                {this.renderTypeField()}
            </ViewGroupItem>
            <ViewGroupItem>
                {this.renderYearField()}
            </ViewGroupItem>
            <ViewGroupItem>
                {this.renderMonthField()}
            </ViewGroupItem>
        </ViewGroup>;
    }

    renderTypeField()
    {
        return <StaticSelectbox
            options={this.store.bespokeStore.typeOptions}
            value={this.store.bespokeStore.selectedType}
            onChange={this.store.bespokeStore.selectType}
        />;
    }

    renderYearField()
    {
        return <StaticSelectbox
            options={this.store.bespokeStore.yearOptions}
            value={this.store.bespokeStore.selectedYear}
            onChange={this.store.bespokeStore.selectYear}
        />;
    }

    renderQuarterField()
    {
        return <StaticSelectbox
            options={this.store.bespokeStore.quarterOptions}
            value={this.store.bespokeStore.selectedQuarter}
            onChange={this.store.bespokeStore.selectQuarter}
        />;
    }

    renderMonthField()
    {
        return <StaticSelectbox
            options={this.store.bespokeStore.monthOptions}
            value={this.store.bespokeStore.selectedMonth}
            onChange={this.store.bespokeStore.selectMonth}
        />;
    }
}