import { observer } from 'mobx-react';
import React from 'react';
import { ContentProps } from '../../Content';
import { useComputed } from 'mobx-react-lite';
import Input from '../../../../../../../../../../Input/Input';
import Base from '../../Base/Base';
import useTypes from '../../../../../../../../../../Type/Api/useTypes';
import { EntityPath } from '../../../../../../../../../../Path/@Model/EntityPath';
import { useExpansion } from '../../../../../../../../../../Selection/Api/useExpansion';


export interface CourseLessonAttenandceProps extends ContentProps
{

}

export const CourseLessonAttendance: React.FC<CourseLessonAttenandceProps> =
    observer(
        ({
             ...props
         }) =>
        {
            const types = useTypes();

            useExpansion(
                props.entity.entityType,
                () =>
                    [
                        EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.CourseLesson.RelationshipDefinition.Attendances,
                            true
                        )
                        .joinTo(
                            types.Activity.Course.RelationshipDefinition.Lessons,
                            true
                        )
                ],
                () =>
                    [
                        props.entity
                    ],
                [
                    props.entity,
                    types
                ]
            );

            const courseLesson =
                useComputed(
                    () =>
                        props.entity.getRelatedEntityByDefinition(
                            true,
                            types.CourseLesson.RelationshipDefinition.Attendances
                        ),
                    [
                        props.entity,
                        types
                    ]
                );

            const course =
                useComputed(
                    () =>
                    {
                        if (courseLesson)
                        {
                            return courseLesson.getRelatedEntityByDefinition(
                                true,
                                types.Activity.Course.RelationshipDefinition.Lessons
                            )
                        }
                    },
                    [
                        courseLesson,
                        types,
                    ]
                );

            const fields =
                [
                    course &&
                    <Input
                        key={1}
                        entity={course}
                        field={types.Activity.Field.Subject}
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                        disabled
                    />,

                    courseLesson &&
                    <Input
                        key={1}
                        entity={courseLesson}
                        field={types.CourseLesson.Field.StartDate}
                        labelPosition={props.labelPosition}
                        inline={props.inlineLabel}
                        disabled
                    />,
                ];

            return <Base
                {...props}
                expand
            >
                {fields}
            </Base>;
        }
    )
