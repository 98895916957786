import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {observer, useComputed} from 'mobx-react-lite';
import QRCode from 'qrcode.react';
import performAction from '../../../@Api/Entity/performAction';
import Dialog from '../../../@Future/Component/Generic/Dialog/Dialog';
import DialogTitle from '../../../@Future/Component/Generic/Dialog/Title/DialogTitle';
import DialogContent from '../../../@Future/Component/Generic/Dialog/Content/DialogContent';
import AppStoreBadge from './resources/app-store.png';
import GooglePlayBadge from './resources/google-play.png';
import uuid from '../../../@Util/Id/uuid';
import RightAlignedButtonGroup from '../../../@Future/Component/Generic/Button/ButtonGroup/RightAlignedButtonGroup';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { useTwoFactorAuthenticationConfiguration } from './Api/useTwoFactorAuthenticationConfiguration';
import PrimaryButton from '../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import AuthenticationManagerContext from '../../../@Service/Authentication/AuthenticationManagerContext';
import LocalizedText from '../Localization/LocalizedText/LocalizedText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { dangerColor } from '../../../@Resource/Theme/Theme';
import { User } from '../../../@Api/Model/Implementation/User';
import TextEditor from "../../../@Future/Component/Generic/Input/TextEditor/TextEditor";
import {loadModuleDirectly} from "../../../@Util/DependencyInjection";
import {FeedbackStore} from "../../App/Root/Environment/Organization/Feedback/FeedbackStore";
import localizeText from "../../../@Api/Localization/localizeText";

const useStyles = makeStyles({
    danger: {
        color: dangerColor,
        fontWeight: "bold",
    },
});

export interface TwoFactorAuthenticationSetupProps
{
    user: User;
    onClose?: () => void;
}

const TwoFactorAuthenticationSetup: React.FC<TwoFactorAuthenticationSetupProps> =
    ({
        user,
        onClose,
    }) =>
    {
        const classes = useStyles();
        const authenticationManagerContext = useContext(AuthenticationManagerContext);

        const [ qrCode, setQrCode ] = useState<string>("");
        const [ secret, setSecret ] = useState<string>("");

        const [ userInputToken, setUserInputToken ] = useState<string>("");
        const [ refreshKey, setRefreshKey ] = useState(() => uuid());

        const [ twoFactorAuthenticationValidated, setTwoFactorAuthenticationValidated ]
            = useState<boolean>(false);

        const [ twoFactorAuthenticationUserConfiguration, isLoadingUserConfiguration] =
            useTwoFactorAuthenticationConfiguration(
                user,
                refreshKey
            );

        const isTwoFactorAuthenticationRequired =
            useMemo<boolean>(
                () =>
                    !isLoadingUserConfiguration && twoFactorAuthenticationUserConfiguration.isRequired,
                [
                    isLoadingUserConfiguration,
                    twoFactorAuthenticationUserConfiguration
                ]
            );
        const isTwoFactorAuthenticationEnabled =
            useMemo<boolean>(
                () =>
                    !isLoadingUserConfiguration && twoFactorAuthenticationUserConfiguration.isEnabled,
                [
                    isLoadingUserConfiguration,
                    twoFactorAuthenticationUserConfiguration
                ]
            );

        const forceSetup =
            useMemo<boolean>(
                () =>
                    isTwoFactorAuthenticationRequired && !isTwoFactorAuthenticationEnabled,
                [
                    isTwoFactorAuthenticationRequired,
                    isTwoFactorAuthenticationEnabled,
                ]
            );

       const enable =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Enable',
                            parameters: {
                                userId: user.id,
                            }
                        }
                    ),
                [
                    user,
                    setQrCode
                ]
            );

        const reset =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Reset',
                            parameters: {
                                userId: user.id,
                            }
                        })
                        .then(
                            (result) =>
                            {
                                const qrUrl = result.result as string;
                                const params = new URLSearchParams(qrUrl.split('?')[1]);
                                const secret = params.get('secret');

                                setSecret(secret)
                                setQrCode(qrUrl);
                                setRefreshKey(uuid())
                            }),
                [
                    user,
                    setQrCode
                ]
            );

        const validateCode =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Validate',
                            parameters: {
                                secret: secret,
                                token: userInputToken,
                                userId: user.id
                            }
                        })
                        .then(
                            (result) =>
                            {
                               if(result.result === "Valid")
                                {
                                    setTwoFactorAuthenticationValidated(true)
                                    setRefreshKey(uuid())
                                    setQrCode(null)
                                    loadModuleDirectly(FeedbackStore)
                                        .enqueueSnackbar(
                                            localizeText(
                                                'TwoFactorAuthentication.ValidatedSuccessfully',
                                                'Twee factor authenticatie succesvol gevalideerd'),
                                            {
                                                variant: 'success'
                                            });
                                }
                               else
                               {
                                   setTwoFactorAuthenticationValidated(false)
                                   loadModuleDirectly(FeedbackStore)
                                       .enqueueSnackbar(
                                           localizeText(
                                               'TwoFactorAuthentication.ValidatedUnsuccessfully',
                                               'Twee factor authenticatie mislukt gevalideerd'),
                                           {
                                               variant: 'error'
                                           });
                               }
                            }),
                [
                    secret,
                    userInputToken
                ]
            )

        const signOut =
            useCallback(
                () =>
                {
                    authenticationManagerContext.logout().finally();
                },
                [
                    authenticationManagerContext
                ]);

        useEffect(
            () =>
            {
                if (
                    (!isLoadingUserConfiguration && !isTwoFactorAuthenticationEnabled)
                    || forceSetup
                )
                {
                    enable()
                        .then(
                            (result) =>
                            {
                                const qrUrl = result.result as string;
                                const params = new URLSearchParams(qrUrl.split('?')[1]);
                                const secret = params.get('secret');

                                setSecret(secret)
                                setQrCode(qrUrl);
                            }
                        )
                }
            },
            [
                isLoadingUserConfiguration,
                isTwoFactorAuthenticationEnabled,
                forceSetup,
            ]);

        const twoFactorAuthenticationStatusInfo =
            useComputed<string>(
                () =>
                {
                    if(twoFactorAuthenticationUserConfiguration)
                    {
                        return twoFactorAuthenticationUserConfiguration.isEnabled ?
                                "Enabled" :
                                "Not Enabled"
                    }
                    return ''
                },
                [
                    twoFactorAuthenticationUserConfiguration
                ]
            )

        return <Dialog
            onClose={onClose}
            hideCloseButton={forceSetup}
            open
        >
            <DialogTitle>
                {
                    twoFactorAuthenticationStatusInfo !== '' &&
					<LocalizedText
						code='TwoFactorAuthentication.Status'
						value='Twee factor authenticatie is ${status}'
						status={twoFactorAuthenticationStatusInfo}
					/>
                }
            </DialogTitle>
            <DialogContent>
                <ViewGroup
                    orientation="vertical"
                    spacing={15}
                >
                    {
                        isTwoFactorAuthenticationEnabled &&
						<ViewGroup
							orientation={"horizontal"}
							spacing={15}
						>
							<ViewGroupItem>
								<PrimaryButton
									label={
                                        <LocalizedText
                                            code="Authentication.TwoFactor.Reset"
                                            value="Twee factor authenticatie resetten"
                                        />
                                    }
									onClick={reset}
								/>
							</ViewGroupItem>
						</ViewGroup>
                    }
                    {
                        qrCode &&
                            <ViewGroupItem>
                                <ViewGroup
                                    orientation="vertical"
                                    spacing={15}
                                >
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code='TwoFactorAuthentication.Step1'
                                            value='<strong>Stap 1:</strong> download de Google Authenticator app.'
                                            containsHTML
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <a
                                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={AppStoreBadge}
                                                width={200}
                                                alt=""
                                            />
                                        </a>
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={GooglePlayBadge}
                                                width={200}
                                                style={{
                                                    marginLeft: 10
                                                }}
                                                alt=""
                                            />
                                        </a>
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code='TwoFactorAuthentication.Step2'
                                            value='<strong>Stap 2:</strong> voeg een profiel toe in de Google Authenticator middels de volgende QR code'
                                            containsHTML
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <QRCode
                                            value={qrCode}
                                            size={250}
                                        />
                                    </ViewGroupItem>
                                    <ViewGroupItem>
                                        <LocalizedText
                                            code='TwoFactorAuthentication.Step3'
                                            value='<strong>Stap 3:</strong> verifiëren uw authenticatiecode'
                                            containsHTML
                                        />
                                    </ViewGroupItem>
                                    <ViewGroup
                                        orientation={"horizontal"}
                                        spacing={15}
                                    >
                                        <ViewGroupItem>
                                            <TextEditor
                                                value={userInputToken}
                                                onChange={setUserInputToken}
                                            />
                                        </ViewGroupItem>
                                        <ViewGroupItem>
                                            <PrimaryButton
                                                label={
                                                    <LocalizedText
                                                        code="TwoFactorAuthentication.Verify"
                                                        value="Verifiëren"
                                                    />
                                                }
                                                onClick={validateCode}
                                            />
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </ViewGroup>
                            </ViewGroupItem>
                    }
                    {
                        twoFactorAuthenticationValidated &&
						<ViewGroupItem>
							<LocalizedText
								code='TwoFactorAuthentication.Step4'
								value='<strong>Stap 4:</strong> gebruik de unieke inlogcode na het invoeren van je e-mail adres en wachtwoord bij het inloggen'
								containsHTML
							/>
						</ViewGroupItem>
                    }
                    <ViewGroupItem>
                        <RightAlignedButtonGroup>
                            {
                                twoFactorAuthenticationValidated &&
								<ViewGroup
									orientation="horizontal"
									spacing={5}
									alignment="center"
								>
									<ViewGroupItem
										className={classes.danger}
										ratio={1}
									>
										<LocalizedText
											code="Authentication.TwoFactor.Warning"
											value="Let op: je kan niet meer kunnen inloggen totdat de nieuwe code geactiveerd is"
										/>
									</ViewGroupItem>
									<ViewGroupItem>
										<PrimaryButton
											label={
                                                <LocalizedText
                                                    code="Authentication.TwoFactor.Login"
                                                    value="Opnieuw inloggen met twee factor authenticatie "
                                                />
                                            }
											onClick={signOut}
										/>
									</ViewGroupItem>
								</ViewGroup>
                            }
                        </RightAlignedButtonGroup>
                    </ViewGroupItem>
                </ViewGroup>
            </DialogContent>
        </Dialog>;

    };

export default observer(TwoFactorAuthenticationSetup);
