import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import DragHandle from '../../../../DragAndDrop/DragHandle/DragHandle';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { WidgetProps } from '../Widget';
import MenuButton from '../../../../../../@Future/Component/Generic/Button/Variant/Menu/MenuButton';
import Menu from '../../../../../../@Future/Component/Generic/Menu/Menu';
import IconButton from '../../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import styles from './Entity.module.scss';
import EntityWidget from '../../Model/Widget/Entity/EntityWidget';
import { useComputed } from 'mobx-react-lite';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import Input from '../../../../../../@Future/Component/Generic/Input/Input/Input';
import { runInAction } from 'mobx';
import { Entity as EntityModel } from '../../../../../../@Api/Model/Implementation/Entity';
import EntityViewer from '../../../Viewer/EntityViewer';
import Centered from '../../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../../@Future/Component/Generic/Loader/Loader';
import { openEntity } from '../../../@Util/openEntity';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import { buttonInset } from '../../../../../../@Resource/Theme/Theme';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import useExpanded from '../../../Dataset/Api/useExpanded';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import Selectbox from '../../../Selectbox/Selectbox';
import PrimaryButton from '../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import DeleteItem from '../../../../../../@Future/Component/Generic/Menu/Item/DeleteItem/DeleteItem';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import useEntityByUuid from '../../../../../../@Api/Entity/Bespoke/useEntityByUuid';

export interface EntityProps extends WidgetProps<EntityWidget>
{

}

const Entity: React.FC<EntityProps> =
    props =>
    {
        const [ isExpanded, toggleExpansion ] = useExpanded(`Widget.${props.widget.id}`);

        const selections =
            useComputed(
                () =>
                    props.widget.entityType
                        ?
                            [
                                new EntitySelectionBuilder(props.widget.entityType).selection
                            ]
                        :
                            [],
                [
                    props.widget
                ]);

        const onChange =
            useCallback(
                (value?: EntityModel) =>
                    runInAction(
                        () =>
                        {
                            if (value)
                            {
                                props.widget.entityId = value.uuid;
                                props.widget.entity = value;
                            }
                            else
                            {
                                props.widget.entityId = undefined;
                                props.widget.entity = undefined;
                            }
                        }),
                [
                    props.widget
                ]);

        const entity = props.widget.entity;

        const openInFullscreen =
            useCallback(
                () =>
                    openEntity(entity),
                [
                    entity
                ]);

        const [ existingEntity ] =
            useEntityByUuid(
                props.widget.entityType,
                props.widget.entityId
            );

        if (!props.widget.entityType && !props.widget.entity)
        {
            return <DragHandle>
                <Card
                    inset
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={5}
                    >
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <LocalizedText
                                    code="InvalidWidget"
                                    value="Deze widget is niet meer geldig."
                                />
                            </Centered>
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Centered
                                horizontal
                            >
                                <PrimaryButton
                                    label={
                                        <LocalizedText
                                            code="Generic.Delete"
                                            value="Verwijderen"
                                        />
                                    }
                                    onClick={props.onDelete}
                                />
                            </Centered>
                        </ViewGroupItem>
                    </ViewGroup>
                </Card>
            </DragHandle>;
        }
        else
        {
            return <Card
                classes={{
                    root: styles.root
                }}
            >
                <ExpansionGroup>
                    <ExpansionPanel
                        id={`Widget.${props.widget.id}`}
                        summary={
                            <DragHandle>
                                <Header
                                    title={
                                        isExpanded
                                            ?
                                                <LocalizedText
                                                    code="Widget.Entity.Pin"
                                                    value="Pin"
                                                />
                                            :
                                                `${props.widget.entityType.getName()}: ${props.widget.entityId ? entity ? entity.name : '' : '...'}`}
                                    large
                                    inset
                                >
                                    <ViewGroup
                                        orientation="horizontal"
                                        spacing={buttonInset}
                                        alignment="center"
                                    >
                                        {
                                            props.onAdd &&
                                                <ViewGroupItem>
                                                    <IconButton
                                                        icon="add"
                                                        onClick={props.onAdd}
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Widget.AddToDashboard"
                                                                value="Toevoegen aan dashboard"
                                                            />
                                                        }
                                                    />
                                                </ViewGroupItem>
                                        }
                                        {
                                            entity &&
                                                <ViewGroupItem>
                                                    <IconButton
                                                        icon="open_in_new"
                                                        tooltip={
                                                            <LocalizedText
                                                                code="Generic.NavigateTo"
                                                                value="Navigeer naar ${resource}"
                                                                resource={props.widget.entityType.getName().toLowerCase()}
                                                            />
                                                        }
                                                        onClick={openInFullscreen}
                                                    />
                                                </ViewGroupItem>
                                        }
                                        <ViewGroupItem>
                                            <MenuButton>
                                                <Menu>
                                                    <DeleteItem
                                                        onClick={props.onDelete}
                                                    />
                                                </Menu>
                                            </MenuButton>
                                        </ViewGroupItem>
                                    </ViewGroup>
                                </Header>
                            </DragHandle>
                        }
                        expansion={
                            <CardInset
                                top={false}
                            >
                                {
                                    !existingEntity &&
                                        <Card
                                            inset
                                        >
                                            <Input
                                                label={
                                                    <LocalizedText
                                                        code="Dashboard.SelectAnEntityToPin"
                                                        value="Kies een ${typeName} om vast te pinnen"
                                                        typeName={props.widget.entityType.getName().toLowerCase()}
                                                    />
                                                }
                                                labelPosition="top"
                                            >
                                                <Selectbox
                                                    selections={selections}
                                                    onChange={onChange}
                                                    value={entity}
                                                />
                                            </Input>
                                        </Card>
                                }
                                {
                                    existingEntity && entity &&
                                        <EntityViewer
                                            entity={entity}
                                            compact
                                        />
                                }
                                {
                                    existingEntity && !entity &&
                                        <Centered
                                            horizontal
                                        >
                                            <Loader />
                                        </Centered>
                                }
                            </CardInset>
                        }
                        expanded={isExpanded}
                        onExpand={toggleExpansion}
                        onCollapse={toggleExpansion}
                    />
                </ExpansionGroup>
            </Card>;
        }
    };

export default observer(Entity);
