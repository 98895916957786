import Predicate from './Predicate';
import { observable } from 'mobx';
import Dependency from '../../../Parameter/Dependency';
import AutomationDependencyContext from '../../../AutomationDependencyContext';
import Validation from '../../../Validation/Validation';
import FunctionContext from '../../FunctionContext';
import Computation from '../Computation';
import ValueType from '../../../Value/Type/ValueType';
import safelyApplyFunction from '../../../Api/safelyApplyFunction';
import safelySynchronousApplyFunction from '../../../Api/safelySynchronousApplyFunction';
import Value from '../../../Value/Value';
import getComputationFromDescriptor from '../../../Api/getComputationFromDescriptor';
import getValueTypeFromDescriptor from '../../../Api/getValueTypeFromDescriptor';
import { ComputationSubstitution } from '../Util/ComputationSubstitution';
import Parameter from '../../../Parameter/Parameter';

export default class InstanceOfPredicate extends Predicate
{
    // ------------------------- Properties -------------------------

    @observable.ref value: Computation<any, any>;
    @observable.ref valueType: ValueType<any>;

    // ------------------------ Constructor -------------------------

    constructor(value: Computation<any, any>,
                valueType: ValueType<any>)
    {
        super();

        this.value = value;
        this.valueType = valueType;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    isAsync(): boolean
    {
        return this.value.isAsync();
    }

    async evaluate(context: FunctionContext): Promise<boolean>
    {
        const value = await safelyApplyFunction(this.value, context);

        return this.isInstanceOf(value, this.valueType);
    }

    synchronouslyEvaluate(context: FunctionContext): boolean
    {
        const value = safelySynchronousApplyFunction(this.value, context);

        return this.isInstanceOf(value, this.valueType);
    }

    isInstanceOf(value: Value<any, any>,
                 valueType: ValueType<any>)
    {
        return value.getType().isA(valueType);
    }

    validate(): Validation[]
    {
        return this.value?.validate() || [];
    }

    getName(): string
    {
        return 'is van type ...';
    }

    augmentDescriptor(descriptor: any)
    {
        descriptor.type = 'InstanceOf';
        descriptor.value = this.value.toDescriptor();
        descriptor.valueType = this.valueType.toDescriptor();
    }

    getDependencies(): Dependency[]
    {
        return this.value?.getDependencies() || [];
    }

    getTrueStatements(
        context: FunctionContext,
        parameter?: Parameter<any>
    ): Predicate[]
    {
        return [];
    }

    normalize(): Predicate
    {
        return this;
    }

    substitute(substitution: ComputationSubstitution): Predicate
    {
        return this;
    }

    toOldPredicate()
    {
        return undefined;
    }

    static async fromDescriptor(descriptor: any,
                                dependencyContext: AutomationDependencyContext)
    {
        return new InstanceOfPredicate(
            await getComputationFromDescriptor(
                descriptor.value,
                dependencyContext),
            await getValueTypeFromDescriptor(
                descriptor.valueType,
                dependencyContext));
    }

    // ----------------------- Private logic ------------------------
}
