import React, { useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { default as ResourcePlannerModel } from '../Model/ResourcePlanner';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import EntityLink from '../../Link/EntityLink';
import { Entity } from '../../../../../@Api/Model/Implementation/Entity';
import Period from '../Model/Period';
import Track from './Track/Track';
import Item from '../Model/Item';
import PeriodSpecification from '../Model/PeriodSpecification';
import ResourcePlanner, { OnPlan, TimesheetTotalFormatter } from '../ResourcePlanner';
import { default as TrackModel } from './Track/Model/Track';
import ViewGroup from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import Avatar from '../../Avatar/Avatar';
import styles from './Resource.module.scss';
import useToggle from '../../../../../@Util/Toggle/useToggle';
import IconButton from '../../../../../@Future/Component/Generic/Button/Variant/Icon/IconButton';
import { classNames } from '../../../../../@Future/Util/Class/classNames';
import ParameterAssignment from '../../../../../@Api/Automation/Parameter/ParameterAssignment';
import EntityValue from '../../../../../@Api/Automation/Value/EntityValue';
import { Duration } from 'moment';

export interface ResourceProps
{
    resourcePlanner: ResourcePlannerModel;
    resources: Entity[];
    resource: Entity;
    parameterAssignment: ParameterAssignment;
    period: Period;
    specification: PeriodSpecification;
    headerCells: Period[];
    dataCells: Period[];
    track: TrackModel;
    items: Item[];
    first?: boolean;
    last?: boolean;
    onPlan?: OnPlan;
    disableHeader?: boolean;
    nestLevel: number;
    timesheet?: boolean;
    disableTimesheetDialog?: boolean;
    timesheetTotalFormatter?: TimesheetTotalFormatter;
    intervalType: string;
    formatResourceName?: (resource: Entity) => React.ReactNode | undefined;
    minimumCellDuration?: Duration;
    expanded?: boolean;
}

const Resource: React.FC<ResourceProps> =
    props =>
    {
        const hasResourceAvatar =
            useComputed(
                () =>
                    props.resource.entityType.bespoke.hasResourceAvatar(props.resource),
                [
                    props.resource
                ]);

        const resourceName =
            useComputed(
                () =>
                    props.resource.entityType.bespoke.getResourceTitle(props.resource),
                [
                    props.resource
                ]);

        const defaultResourceNameComponent =
            useComputed(
                () =>
                    <EntityLink
                        entity={props.resource}
                    >
                        {resourceName}
                    </EntityLink>,
           [
               props.resource,
               resourceName
           ]
        )

        const resourceNameComponent =
            useComputed(
                () =>
                    props.formatResourceName
                        ? props.formatResourceName(props.resource) ?? defaultResourceNameComponent
                        : defaultResourceNameComponent,
                [
                    props.resource,
                    props.formatResourceName,
                    defaultResourceNameComponent
                ]
            );

        const [isSegmentOpen, toggleSegment] = useToggle(props.expanded);

        const resourceNameStyle =
            useMemo(
                () =>
                {
                    if (props.nestLevel !== 0)
                    {
                        const margin =
                            props.resourcePlanner.childSelections.length > 0
                                ? props.nestLevel * 20
                                : (props.nestLevel - 1) * 20 + 60
                        return ({marginLeft: margin});
                    }
                    else
                    {
                        return ({marginLeft: 0});
                    }
                },
            [
                props.nestLevel,
                props.resourcePlanner.childSelections
            ]
        );

        const trackItems =
            useComputed(
                () =>
                    props.items
                        .map(
                            item =>
                                props.track.getTrackItem(item)
                        ),
                [
                    props.items,
                    isSegmentOpen,
                    props.track
                ]
            );

        const totalDuration =
            useComputed(
                () =>
                {
                    let duration = 0;

                    trackItems
                        .forEach(
                            item =>
                            {
                                duration += item.item.period.to.diff(item.item.period.from, 'milliseconds');
                            });

                    return duration;
                },
                [
                    trackItems,
                    props.track
                ]);

        const childParameterAssignment =
            useComputed(
                () =>
                    new ParameterAssignment(
                        new Map(props.parameterAssignment.valueByParameter))
                        .setValue(
                            props.resourcePlanner.resourceParameter,
                            new EntityValue(props.resource)),
                [
                    props.parameterAssignment,
                    props.resourcePlanner,
                    props.resource
                ]);

        return <>
            <TableRow
                className={classNames(styles.row, props.nestLevel > 0 && styles.nested, props.last && styles.last, isSegmentOpen && styles.open)}
            >
                <TableCell
                    className={styles.resourceCell}
                >
                    <ViewGroup
                        orientation="horizontal"
                        spacing={10}
                        alignment="center"
                    >
                        {
                            props.resourcePlanner.childSelections.length > 0 &&
                            <ViewGroupItem>
                                <IconButton
                                    onClick={toggleSegment}
                                    icon={isSegmentOpen ? 'expand_less' : 'expand_more'}
                                    tooltip={`${props.resourcePlanner.childSelections[0].resourceType.getName(true)} ${isSegmentOpen ? 'sluiten' : 'tonen'}`}
                                />
                            </ViewGroupItem>
                        }
                        {
                            hasResourceAvatar &&
                            <ViewGroupItem>
                                <Avatar
                                    entity={props.resource}
                                    size={20}
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem
                        >
                            <div
                                className={classNames(styles.resourceName, isSegmentOpen && styles.open)}
                                style={resourceNameStyle}
                                title={resourceName}
                            >
                                {resourceNameComponent}
                            </div>
                        </ViewGroupItem>
                    </ViewGroup>
                </TableCell>
                <TableCell
                    className={styles.trackCell}
                    padding="none"
                >
                    <Track
                        {...props}
                        trackItems={trackItems}
                    />
                </TableCell>
                <TableCell
                    className={classNames(styles.totalCell, props.timesheet && styles.timesheet)}
                    padding="none"
                    align="right"
                >
                    {props.timesheetTotalFormatter(totalDuration, props.period, props.resources, props.intervalType, props.resource)}
                </TableCell>
            </TableRow>
            {
                isSegmentOpen &&
                <TableRow
                    className={classNames(styles.expansionRow, props.nestLevel > 0 && styles.nested, props.last && styles.last)}
                >
                    <TableCell
                        padding="none"
                        colSpan={3}
                        className={styles.cell}
                    >
                        {
                            props.resourcePlanner.childSelections
                                .map(
                                    childSelection =>
                                        <ResourcePlanner
                                            resourcePlanner={childSelection}
                                            period={props.period}
                                            disableHeader
                                            nestLevel={props.nestLevel + 1}
                                            timesheet={props.timesheet}
                                            disableTimesheetDialog={props.disableTimesheetDialog}
                                            parameterAssignment={childParameterAssignment}
                                            formatResourceName={props.formatResourceName}
                                            intervalType={props.intervalType}
                                            minimumCellDuration={props.minimumCellDuration}
                                        />
                                )
                        }
                    </TableCell>
                </TableRow>
            }
        </>;
    };

export default observer(Resource);
