import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { observer } from 'mobx-react-lite';
import ViewGroup from '../../ViewGroup/ViewGroup';
import ViewGroupItem from '../../ViewGroup/ViewGroupItem';
import TabBar from '../../TabBar/TabBar';
import Tab from '../../TabBar/Tab/Tab';
import DailyCronEditor from './Type/DailyCronEditor';
import MinutelyCronEditor from './Type/MinutelyCronEditor';
import HourlyCronEditor from './Type/HourlyCronEditor';
import WeeklyCronEditor from './Type/WeeklyCronEditor';
import MonthlyCronEditor from './Type/MonthlyCronEditor';
import YearlyCronEditor from './Type/YearlyCronEditor';
import CardInset from '../../Card/CardInset';
import LocalizedText from '../../../../../@Component/Domain/Localization/LocalizedText/LocalizedText';

export interface CronEditorProps
{
    value?: string;
    onChange: (value: string) => void;
}

export const every15MinutesCron = '0 0/15 * 1/1 * ?';
export const everyHourCron = '0 0 0/1 1/1 * ?';
export const everyDayAt12Cron = '0 0 12 1/1 * ?';
export const everyWeekOnMondayAt12Cron = '0 0 12 ? * MON';
export const everyMonthOnFirstDayAt12Cron = '0 0 12 1 1/1 ?';
export const everyFirstDayOfYearCron = '0 0 12 1 1 ?';

type CronType = 'Minutely' | 'Hourly' | 'Daily' | 'Weekly' | 'Monthly' | 'Yearly';

// http://www.cronmaker.com

const getCronType = (value: string) =>
{
    const split = value.split(' ');

    if (['1/1', '*'].includes(split[3]) && split[4] === '*' && ['?', '*'].includes(split[5]))
    {
        if (split[1].split('/').length === 2)
        {
            return 'Minutely';
        }
        else if (split[2].split('/').length === 2)
        {
            return 'Hourly';
        }
        else
        {
            return 'Daily';
        }
    }
    else if (split[4] === '*' && split[5] !== '*')
    {
        return 'Weekly';
    }
    else if (split[4].split('/').length === 2)
    {
        return 'Monthly';
    }
    else
    {
        return 'Yearly';
    }
}

const CronEditor: React.FC<CronEditorProps> =
    props =>
    {
        const [savedType, setSavedType] = useState(null);
        const [savedValue, setSavedValue] = useState(null);

        useEffect(
            () =>
            {
                if(props.value)
                {
                    setSavedType(getCronType(props.value));
                    setSavedValue(props.value);
                }
            },
            []
        );

        const type =
            useMemo(
                () =>
                    getCronType(props.value),
                [
                    props.value
                ]);

        const { onChange } = props;
        const setType =
            useCallback(
                (type: CronType) =>
                {
                    if(type === savedType)
                    {
                        onChange(savedValue);
                        return;
                    }
                    switch (type)
                    {
                        case 'Minutely':
                            onChange(every15MinutesCron);
                            break;

                        case 'Hourly':
                            onChange(everyHourCron);
                            break;

                        case 'Daily':
                            onChange(everyDayAt12Cron);
                            break;

                        case 'Weekly':
                            onChange(everyWeekOnMondayAt12Cron);
                            break;

                        case 'Monthly':
                            onChange(everyMonthOnFirstDayAt12Cron);
                            break;

                        case 'Yearly':
                            onChange(everyFirstDayOfYearCron);
                            break;
                    }
                },
                [
                    savedType,
                    savedValue,
                    onChange
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <ViewGroupItem>
                <TabBar
                    value={type}
                >
                    <Tab
                        value="Minutely"
                        onClick={setType}
                    >
                        <LocalizedText
                            code="Generic.Minutely"
                            value="Iedere minuut"
                        />
                    </Tab>
                    <Tab
                        value="Hourly"
                        onClick={setType}
                    >
                        <LocalizedText
                            code="Generic.Hourly"
                            value="Ieder uur"
                        />
                    </Tab>
                    <Tab
                        value="Daily"
                        onClick={setType}
                    >
                        <LocalizedText
                            code="Generic.Daily"
                            value="Dagelijks"
                        />
                    </Tab>
                    <Tab
                        value="Weekly"
                        onClick={setType}
                    >
                        <LocalizedText
                            code="Generic.Weekly"
                            value="Wekelijks"
                        />
                    </Tab>
                    <Tab
                        value="Monthly"
                        onClick={setType}
                    >
                        <LocalizedText
                            code="Generic.Monthly"
                            value="Maandelijks"
                        />
                    </Tab>
                    <Tab
                        value="Yearly"
                        onClick={setType}
                    >
                        <LocalizedText
                            code="Generic.Yearly"
                            value="Jaarlijks"
                        />
                    </Tab>
                </TabBar>
            </ViewGroupItem>
            <ViewGroupItem>
                <CardInset
                    vertical={false}
                >
                    {
                        type === 'Minutely' &&
                            <MinutelyCronEditor
                                {...props}
                            />
                    }
                    {
                        type === 'Hourly' &&
                            <HourlyCronEditor
                                {...props}
                            />
                    }
                    {
                        type === 'Daily' &&
                            <DailyCronEditor
                                {...props}
                            />
                    }
                    {
                        type === 'Weekly' &&
                            <WeeklyCronEditor
                                {...props}
                            />
                    }
                    {
                        type === 'Monthly' &&
                            <MonthlyCronEditor
                                {...props}
                            />
                    }
                    {
                        type === 'Yearly' &&
                            <YearlyCronEditor
                                {...props}
                            />
                    }
                </CardInset>
            </ViewGroupItem>
        </ViewGroup>;
    };

CronEditor.defaultProps = {
    value: everyDayAt12Cron
};

export default observer(CronEditor);
