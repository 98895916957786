import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './Relationship.module.scss';
import Header from './Header/Header';
import Details from '../../Shared/Details/Details';
import Contacts from './Contacts/Contacts';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import { createTransactionalModel, getModel } from '../../../../../../@Util/TransactionalModelV2/index';
import useTypes from '../../../Type/Api/useTypes';
import useFirstParentRelationship from '../../../../../../@Api/Entity/Bespoke/Relation/useFirstParentRelationship';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import IsCompactContext from '../../Context/IsCompactContext';
import isHiddenType from '../../../../../../@Api/Metadata/EntityType/isHiddenType';
import EntityTypeContext from '../../../Type/EntityTypeContext';
import InvolvedList from './InvolvedList/InvolvedList';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import ChildRelationships from './ChildRelationships/ChildRelationships';
import useChildRelation from '../../../../../../@Api/Entity/Bespoke/Relationship/useChildRelation';
import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import TimeSheet from '../Activity/TimeRegistration/TimeSheet/TimeSheet';
import EntityCalendar from '../../../Calendar/EntityCalendar';
import Labels from './Labels/Labels';
import ParticipationList from './ParticipationList/ParticipationList';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import Portals from './Portals/Portals';
import getCustomPluralRelatedPaths from '../Activity/Api/getCustomPluralRelatedPaths';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import ChildAccountList from './ChildAccountList/ChildAccountList';
import PageTabBar, { TabFamily } from '../../Shared/Page/TabBar/PageTabBar';
import ActivityList from '../Activity/RelatedActivityList/Model/ActivityList';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../@Util/Id/uuid';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ActivityListItem from '../Activity/RelatedActivityList/Model/ActivityListItem';
import RelatedActivityListRoot from '../Activity/RelatedActivityList/RelatedActivityListRoot';
import RelatedActivityList from '../Activity/RelatedActivityList/RelatedActivityList';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import MileageSheet from '../Activity/MileageRegistration/MileageSheet/MileageSheet';
import useAggregateResult from '../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import useCount from '../../../Selection/Hooks/useCount';
import { useRoutingState } from '../../../../../../@Service/Router/Model/Api/useRoutingState';
import GoogleMapWithAddresses from '../../../../../Generic/GoogleMaps/GoogleMapWithAddresses';
import TimeRegistrationDuration from '../Activity/TimeRegistration/TimeRegistrationDuration/TimeRegistrationDuration';
import MileageRegistrationDistance from '../Activity/MileageRegistration/MileageRegistrationDistance/MileageRegistrationDistance';
import RelatedEntityCount from '../../Shared/RelatedEntityCount/RelatedEntityCount';
import { useUserLocalSettingCode } from '../../../../Setting/Api/useUserLocalSettingCode';
import useLocalSetting from '../../../../Setting/Api/useLocalSetting';
import { ResizableTwoColumnLayout } from '../../Shared/ResizableTwoColumLayout.tsx/ResizableTwoColumnLayout';
import { useIsNotLgScreen } from '../../../../../../@Util/Responsiveness/useIsNotLgScreen';
import useOpenedEntity from '../../Context/OpenedEntity/useOpenedEntity';

export interface RelationProps
{
    entity: Entity;
    commitContext: CommitContext;
    withRelation?: Entity;
}

const Relationship: React.FC<RelationProps> =
    props =>
    {
        const types = useTypes();
        const entityTypeStore = useContext(EntityTypeContext);
        const isCompact = useContext(IsCompactContext);
        const isNotLgScreen = useIsNotLgScreen();
        const isContact =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Relationship.Person.Contact.Type),
                [
                    props.entity,
                    types
                ]
            );
        const isEmployee =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Relationship.Person.Contact.Employee.Type),
                [
                    props.entity,
                    types
                ]
            );
        const relatedActivityPaths =
            useComputed(
                () => [
                    ...[
                        ...types.Activity.Type
                            .getAllInstantiableTypes()
                            .filter(
                                type =>
                                    !isHiddenType(type) &&
                                    !type.isA(types.Activity.ApsisCampaign.Type) &&
                                    !type.isA(types.Activity.ApsisCampaignResult.Type) &&
                                    !type.isA(types.Activity.ApsisForm.Type) &&
                                    !type.isA(types.Activity.ApsisFormResult.Type)
                            )
                    ].map(
                        activityType =>
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    isContact
                                        ? types.Relationship.Person.Contact.RelationshipDefinition.Activities
                                        : types.Relationship.RelationshipDefinition.Activities,
                                    false)
                                .castTo(activityType)),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Attachments,
                            false),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Notes,
                            false),
                    ...types.LeexiCall.Type
                        ? [
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    isContact
                                        ? types.Relationship.Person.Contact.RelationshipDefinition.ContactLeexiCallParticipants
                                        : types.Relationship.RelationshipDefinition.LeexiCallParticipants,
                                    false
                                )
                                .joinTo(
                                    types.LeexiCall.RelationshipDefinition.Participants,
                                    true
                                )
                        ]
                        : []
                ],
                [
                    props.entity,
                    types,
                    entityTypeStore,
                    isContact
                ]
            );
        const parentRelationOfContact =
            useComputed(
                () =>
                    isContact
                        ?
                            props.entity.getRelatedEntityByDefinition(
                                true,
                                types.Relation.RelationshipDefinition.Relationships)
                        :
                            undefined,
                [
                    isContact,
                    props.entity,
                    types
                ]
            );
        const firstParentRelationshipOfContact =
            useFirstParentRelationship(
                parentRelationOfContact,
                true
            );
        const onConstruct =
            useCallback(
                (entity: Entity) =>
                {
                    if (entity.entityType.isA(types.Activity.Type)
                        && isContact
                        && firstParentRelationshipOfContact)
                    {
                        // Set relationship on action (contact is already set), see relatedActivityPaths
                        entity.updateRelationship(
                            true,
                            types.Relationship.RelationshipDefinition.Activities,
                            createTransactionalModel(
                                getModel(firstParentRelationshipOfContact)));
                    }
                },
                [
                    types,
                    isContact,
                    firstParentRelationshipOfContact
                ]
            );
        const isMore =
            useCallback(
                (path: EntityPath) =>
                    (!isMutable(path.entityType.entity)
                        && path.entityType.isA(types.Activity.Type)
                        && !path.entityType.isA(types.Activity.Task.Type)
                        && !path.entityType.isA(types.Activity.Appointment.Type))
                        || path.entityType.isA(types.TimeRegistration.Type),
                [
                    types
                ]
            );
        const isConstructable =
            useCallback(
                (path: EntityPath) =>
                    !path.entityType.isA(types.Activity.Offer.Type) &&
                    !path.entityType.isA(types.LeexiCall.Type),
                [
                    types
                ]
            );
        const relation = useChildRelation(props.entity);
        const [ hasMultipleRelationshipManagement ] = useSetting<boolean>(SettingSource.Organization, 'CRM.MultipleRelationshipManagement');
        const showRelationships =
            relation
            && !relation.entityType.isA(types.Relation.Person.Type);
        const pluralRelatedPaths =
            useComputed(
                () =>
                    getCustomPluralRelatedPaths(props.entity.entityType),
                [
                    props.entity,
                    types
                ]
            );
        const activityList =
            useMemo(
                () =>
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        relatedActivityPaths.map(
                            path =>
                                new ActivityListItem(
                                    new Parameter(
                                        uuid(),
                                        new EntityValueType(path.entityType),
                                        true,
                                        path.entityType.getName()),
                                    path,
                                    isConstructable(path),
                                    isMore(path)))),
                [isConstructable, isMore, props.entity.entityType, relatedActivityPaths]
            );
        const involvedInActivityPaths =
            useComputed(
                () => [
                    ...[
                        ...types.Activity.Type
                            .getAllInstantiableTypes()
                            .filter(
                                type =>
                                    !isHiddenType(type))
                    ].map(
                        activityType =>
                            EntityPath.fromEntity(props.entity)
                                .joinTo(
                                    isContact
                                        ? types.Involved.RelationshipDefinition.Contact
                                        : types.Involved.RelationshipDefinition.Relationship,
                                    true)
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Involved,
                                    true)
                                .castTo(activityType))
                ],
                [
                    props.entity,
                    types,
                    isContact,
                ]
            );
        const involvedInActivityList =
            useMemo(
                () =>
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        involvedInActivityPaths.map(
                            path =>
                                new ActivityListItem(
                                    new Parameter(
                                        uuid(),
                                        new EntityValueType(path.entityType),
                                        true,
                                        path.entityType.getName()),
                                    path,
                                    false,
                                    false))),
                [involvedInActivityPaths, props.entity.entityType]
            );
        const involvedInCountResult =
            useAggregateResult(
                types.Involved.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>
                                cb.relatedToEntity(
                                    rootPath
                                        .joinTo(
                                            isContact
                                                ? types.Involved.RelationshipDefinition.Contact
                                                : types.Involved.RelationshipDefinition.Relationship,
                                            false
                                        ),
                                    props.entity
                                )
                        )
                        .aggregateOn(
                            rootPath
                                .joinTo(
                                    types.Activity.RelationshipDefinition.Involved,
                                    true
                                )
                                .field(types.Entity.Field.Id),
                            undefined,
                            Aggregate.Count,
                            true
                        ),
                [
                    types,
                ]
            );
        const involvedInCount: number | undefined =
            useMemo(
                () =>
                    involvedInCountResult?.aggregates[0].value,
                [
                    involvedInCountResult,
                ]
            );
        const [ activityCount, setActivityCount ] = useState<number>();
        const projectMemberOfActivityList =
            useMemo(
                () =>
                    props.entity.entityType.isA(types.Relationship.Person.Contact.Employee.Type) &&
                    types.Activity.Project.Type &&
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        [
                            new ActivityListItem(
                                new Parameter(
                                    uuid(),
                                    new EntityValueType(types.Activity.Project.Type),
                                    true,
                                    types.Activity.Project.Type.getName()
                                ),
                                EntityPath.fromEntity(props.entity)
                                    .joinTo(
                                        types.ProjectMember.RelationshipDefinition.Employee,
                                        true
                                    )
                                    .joinTo(
                                        types.Activity.Project.RelationshipDefinition.Members,
                                        true
                                    ),
                                false,
                                false
                            )
                        ]
                    ),
                [
                    props.entity,
                    types,
                ]
            );
        const projectMemberOfCount =
            useCount(
                types.ProjectMember.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.ProjectMember.RelationshipDefinition.Employee,
                                    false
                                ),
                                props.entity
                            )
                    ),
                [
                    types,
                    props.entity,
                ]
            );
        const workOrderMemberOfActivityList =
            useMemo(
                () =>
                    props.entity.entityType.isA(types.Relationship.Person.Contact.Employee.Type) &&
                    types.Activity.WorkOrder.Type &&
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        [
                            new ActivityListItem(
                                new Parameter(
                                    uuid(),
                                    new EntityValueType(types.Activity.WorkOrder.Type),
                                    true,
                                    types.Activity.WorkOrder.Type.getName()
                                ),
                                EntityPath.fromEntity(props.entity)
                                    .joinTo(
                                        types.WorkOrderMember.RelationshipDefinition.Employee,
                                        true
                                    )
                                    .joinTo(
                                        types.Activity.WorkOrder.RelationshipDefinition.Members,
                                        true
                                    ),
                                false,
                                false
                            )
                        ]
                    ),
                [
                    props.entity,
                    types,
                ]
            );
        const workOrderMemberOfCount =
            useCount(
                types.WorkOrderMember.Type,
                (builder, rootPath) =>
                    builder.where(
                        cb =>
                            cb.relatedToEntity(
                                rootPath.joinTo(
                                    types.WorkOrderMember.RelationshipDefinition.Employee,
                                    false
                                ),
                                props.entity
                            )
                    ),
                [
                    types,
                    props.entity,
                ]
            );
        const leftColumn =
            useMemo(
                () =>
                    <ViewGroup
                        orientation="vertical"
                        spacing={16}
                    >
                        <ViewGroupItem>
                            <Details
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <GoogleMapWithAddresses
                                relation={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Contacts
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        {
                            types.Relationship.RelationshipDefinition.ChildAccounts &&
                            <ViewGroupItem>
                                <ChildAccountList
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <InvolvedList
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        {
                            types.EventParticipation.Type &&
                            <ViewGroupItem>
                                <ParticipationList
                                    entity={props.entity}
                                />
                            </ViewGroupItem>
                        }
                        {
                            showRelationships && relation &&
                            <ViewGroupItem>
                                <ChildRelationships
                                    entity={relation}
                                    former
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <Labels
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        {
                            hasMultipleRelationshipManagement && relation &&
                            <ViewGroupItem>
                                <ChildRelationships
                                    entity={relation}
                                />
                            </ViewGroupItem>
                        }
                        <ViewGroupItem>
                            <Portals
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                    </ViewGroup>,
                [hasMultipleRelationshipManagement, props.entity, relation, showRelationships, types.EventParticipation.Type, types.Relationship.RelationshipDefinition.ChildAccounts]
            );
        const [ tab, setTab ] =
            useRoutingState(
                `Entity.${props.entity.id}.TabId`,
                'Static:Activities'
            );
        const tabFamilies =
            useMemo<TabFamily[]>(
                () => [
                    {
                        id: 'Static',
                        tabs: [
                            ...isNotLgScreen
                                ? [
                                    {
                                        id: 'Details',
                                        name:
                                            <LocalizedText
                                                code="Generic.Details"
                                                value="Details"
                                            />,
                                        content:
                                            () =>
                                                leftColumn
                                    }
                                ]
                                : [],
                            {
                                id: 'Activities',
                                name:
                                    <LocalizedText
                                        code="Generic.Activities"
                                        value="Activiteiten"
                                    />,
                                nameAppendix:
                                    activityCount > 0 && activityCount,
                                content:
                                    tabProps =>
                                        <RelatedActivityListRoot
                                            key="Activities"
                                            list={activityList}
                                            entity={props.entity}
                                            onConstruct={onConstruct}
                                            onCount={setActivityCount}
                                            showCreationItem
                                            {...tabProps}
                                        />
                            },
                            {
                                id: 'InvolvedInActivities',
                                name:
                                    <LocalizedText
                                        code="Generic.InvolvedIn"
                                        value="Betrokken in"
                                    />,
                                nameAppendix:
                                    involvedInCount > 0 && involvedInCount,
                                isVisible:
                                    () =>
                                        involvedInCount > 0,
                                content:
                                    tabProps =>
                                        <RelatedActivityList
                                            key="InvolvedInActivities"
                                            list={involvedInActivityList}
                                            entity={props.entity}
                                            onConstruct={() => {}}
                                            {...tabProps}
                                        />
                            },
                            {
                                id: 'ProjectMemberOf',
                                name:
                                    <LocalizedText
                                        code="Generic.ProjectMemberOf"
                                        value="Projectlid van"
                                    />,
                                nameAppendix:
                                    projectMemberOfCount > 0 && projectMemberOfCount,
                                isVisible:
                                    () =>
                                        projectMemberOfActivityList &&
                                        projectMemberOfCount > 0,
                                content:
                                    tabProps =>
                                        <RelatedActivityList
                                            key="ProjectMemberOf"
                                            list={projectMemberOfActivityList}
                                            entity={props.entity}
                                            onConstruct={() => {}}
                                            {...tabProps}
                                        />
                            },
                            {
                                id: 'WorkOrderMemberOf',
                                name:
                                    <LocalizedText
                                        code="Generic.WorkOrderMemberOf"
                                        value="Werkbonlid van"
                                    />,
                                nameAppendix:
                                    workOrderMemberOfCount > 0 && workOrderMemberOfCount,
                                isVisible:
                                    () =>
                                        workOrderMemberOfActivityList &&
                                        workOrderMemberOfCount > 0,
                                content:
                                    tabProps =>
                                        <RelatedActivityList
                                            key="WorkOrderMemberOf"
                                            list={workOrderMemberOfActivityList}
                                            entity={props.entity}
                                            onConstruct={() => {}}
                                            {...tabProps}
                                        />
                            },
                            {
                                id: 'Agenda',
                                name:
                                    <LocalizedText
                                        code="Generic.Agenda"
                                        value="Agenda"
                                    />,
                                content:
                                    () =>
                                        <Card
                                            inset
                                        >
                                            <EntityCalendar
                                                viewType="week"
                                                defaultEmployee={props.entity}
                                            />
                                        </Card>,
                                isVisible:
                                    () =>
                                        props.entity.entityType.isA(types.Relationship.Person.Contact.Employee.Type)
                            },
                            {
                                id: 'Hours',
                                name:
                                    <LocalizedText
                                        code="Generic.Hours"
                                        value="Uren"
                                    />,
                                nameAppendix:
                                    <TimeRegistrationDuration
                                        entity={props.entity}
                                        isEmployee={isEmployee}
                                    />,
                                isVisible: () => types.TimeRegistration.Type && !props.entity.entityType.isA(types.Relationship.Person.Contact.Standard.Type),
                                content:
                                    () =>
                                        <TimeSheet
                                            relationship={props.entity}
                                        />
                            },
                            {
                                id: 'Mileage',
                                name:
                                    <LocalizedText
                                        code="Generic.Mileage"
                                        value="Kilometers"
                                    />,
                                nameAppendix:
                                    <MileageRegistrationDistance
                                        entity={props.entity}
                                        isEmployee={isEmployee}
                                    />,
                                isVisible: () => types.MileageRegistration.Type && !props.entity.entityType.isA(types.Relationship.Person.Contact.Standard.Type),
                                content:
                                    () =>
                                        <MileageSheet
                                            relationship={props.entity}
                                        />
                            },
                        ]
                    },
                    {
                        id: 'PluralRelatedPaths',
                        tabs:
                            pluralRelatedPaths.map(
                                path =>
                                    ({
                                        id: path.id,
                                        name: path.getName(undefined, false),
                                        nameAppendix:
                                            <RelatedEntityCount
                                                entity={props.entity}
                                                path={path}
                                            />,
                                        content:
                                            () =>
                                                <RelatedEntityView
                                                    entity={props.entity}
                                                    path={path}
                                                />
                                    }))
                    }
                ],
                [isNotLgScreen, activityCount, involvedInCount, projectMemberOfCount, workOrderMemberOfCount, props.entity, isEmployee, pluralRelatedPaths, leftColumn, activityList, onConstruct, involvedInActivityList, projectMemberOfActivityList, workOrderMemberOfActivityList, types.Relationship.Person.Contact.Employee.Type, types.Relationship.Person.Contact.Standard.Type, types.TimeRegistration.Type, types.MileageRegistration.Type]
            );
        const openedEntity = useOpenedEntity();
        const [childActivity, setChildActivity] = useState<Entity>();

        useEffect(
            () =>
            {
                if(openedEntity) {
                    setChildActivity(openedEntity)
                }
            },
            [
                openedEntity
            ]);

        const lastSelectedTabIdSettingCode =
            useUserLocalSettingCode(
                'Relationship.PageTabBar.LastSelectedTabId'
            );
        const [ lastSelectedTabId, setLastSelectedTabId ] =
            useLocalSetting<string>(
                lastSelectedTabIdSettingCode,
                tab
            );
        const [ openedEntitySelectedTabId, setOpenedEntitySelectedTabId ] =
            useState<string>(
                'Static:Activities'
            );

        const tabId =
            useMemo(
                () =>
                    childActivity
                        ? openedEntitySelectedTabId
                        : lastSelectedTabId,
                [
                    childActivity,
                    openedEntitySelectedTabId,
                    lastSelectedTabId
                ]);

        const onChange =
            useCallback(
                (tabId: string) =>
                {
                    setOpenedEntitySelectedTabId(tabId);
                    setLastSelectedTabId(tabId);
                    setTab(tabId);
                },
                [
                    setOpenedEntitySelectedTabId,
                    setLastSelectedTabId,
                    setTab,
                ]
            );
        const rightColumn =
            <PageTabBar
                entity={props.entity}
                tabFamilies={tabFamilies}
                tabId={tabId}
                onChangeTab={onChange}
                commitContext={props.commitContext}
            />;

        if (isCompact)
        {
            return <Header
                entity={props.entity}
                commitContext={props.commitContext}
            />;
        }
        else
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <div
                        className={styles.header}
                    >
                        <Header
                            entity={props.entity}
                            commitContext={props.commitContext}
                        />
                    </div>
                    <div
                        className={styles.content}
                    >
                        {
                            isNotLgScreen
                                ? rightColumn
                                : <ResizableTwoColumnLayout
                                    id={`EntityType.${types.Relationship.Type.id}`}
                                    leftColumn={leftColumn}
                                    rightColumn={rightColumn}
                                />
                        }
                    </div>
                </div>
            </BaseLayout>;
        }
    };

export default observer(Relationship);
