import React, { useCallback, useContext, useMemo } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import { Entity } from '../../../../../../../../@Api/Model/Implementation/Entity';
import useTypes from '../../../../../Type/Api/useTypes';
import PrimaryButton, { PrimaryButtonProps } from '../../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton';
import { EntityType } from '../../../../../../../../@Api/Model/Implementation/EntityType';
import sendDocument from '../Api/sendDocument';
import localizeText from '../../../../../../../../@Api/Localization/localizeText';
import useRelatedEntity from '../../../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import currentUserContext from '../../../../../../User/CurrentUserContext';

export interface SendDocumentButtonProps extends Partial<PrimaryButtonProps>
{
    entity: Entity;
    actionCode: string;
    emailTemplateType: EntityType;
    label?: React.ReactNode;
    entityByParameter?: Map<string, Entity>;
}

const SendDocumentButton: React.FC<SendDocumentButtonProps> =
    props =>
    {
        const types = useTypes();
        const currentUserStore = useContext(currentUserContext);

        const canCreateEmail =
            useMemo(
                () =>
                    currentUserStore.rightProfile.canCreate(types.Activity.Email.Type),
                [
                    currentUserStore,
                    types,
                ]
            );

        const parentOfDocument =
            useRelatedEntity(
                props.entity,
                types.Activity.RelationshipDefinition.LinkedActivities,
                true
            );

        const label =
            useComputed(
                () =>
                    props.label || localizeText('Generic.Send', 'Verzenden'),
                [
                    props.label,
                    props.entity
                ]);

        const onClick =
            useCallback(
                () =>
                    sendDocument(
                        props.entity,
                        props.emailTemplateType,
                        props.actionCode,
                        undefined,
                        parentOfDocument,
                        props.entityByParameter),
                [
                    props.entity,
                    props.emailTemplateType,
                    props.actionCode,
                    parentOfDocument,
                    props.entityByParameter
                ]);

        if (!canCreateEmail)
        {
            return null;
        }

        return <PrimaryButton
            {...props}
            icon="email"
            label={label}
            onClick={onClick}
        />;
    };

export default observer(SendDocumentButton);
