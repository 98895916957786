import {Entity} from "../../../../../../../@Api/Model/Implementation/Entity";
import {observer, useComputed} from "mobx-react-lite";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import uuid from "../../../../../../../@Util/Id/uuid";
import {
    useTwoFactorAuthenticationConfiguration
} from "../../../../../Security/Api/useTwoFactorAuthenticationConfiguration";
import performAction from "../../../../../../../@Api/Entity/performAction";
import useEntityValue from "../../../../../../../@Api/Entity/Hooks/useEntityValue";
import useTypes from "../../../../Type/Api/useTypes";
import Card from "../../../../../../../@Future/Component/Generic/Card/Card";
import CardInset from "../../../../../../../@Future/Component/Generic/Card/CardInset";
import CardHeader from "../../../../../../../@Future/Component/Generic/Label/Variant/CardHeader/CardHeader";
import LocalizedText from "../../../../../Localization/LocalizedText/LocalizedText";
import PrimaryButton from "../../../../../../../@Future/Component/Generic/Button/Variant/PrimaryButton/PrimaryButton";
import {Grid} from "@material-ui/core";

export interface UserTwoFactorAuthenticationProps
{
    portalUser: Entity
}

const PortalUserTwoFactorAuthentication: React.FC<UserTwoFactorAuthenticationProps> =
    props =>
    {
        const types = useTypes();
        const [ refreshKey, setRefreshKey ] = useState(() => uuid());
        const user =
            useComputed(
                () => props.portalUser.user,
                [
                    props.portalUser
                ]
            );

        useEffect(
            () =>
            {
                setRefreshKey(uuid());
            },
            [
                props.portalUser,
            ]
        );

        const hasTwoFactorAuthentication =
            useEntityValue(
                props.portalUser,
                types.PortalUser.Field.HasTwoFactorAuthentication
            );

        const [ twoFactorAuthenticationUserConfiguration, isLoadingUserConfiguration] =
            useTwoFactorAuthenticationConfiguration(
                user,
                refreshKey
            );

        const isTwoFactorAuthenticationEnabled =
            useMemo<boolean>(
                () =>
                    !isLoadingUserConfiguration && twoFactorAuthenticationUserConfiguration?.isEnabled,
                [
                    isLoadingUserConfiguration,
                    twoFactorAuthenticationUserConfiguration
                ]
            );

        const resetTwoFactorAuthentication =
            useCallback(
                () =>
                    performAction(
                        undefined,
                        {
                            code: 'Authentication.TwoFactor.Reset',
                            parameters: {
                                userId: user.id,
                            }
                        }
                    )
                        .then(),
                [
                    user
                ]
            );

        return <>
            {
                hasTwoFactorAuthentication &&
				<Grid
					item
					xs={12}
				>
					<Card>
						<CardInset>
							<CardHeader>
								<LocalizedText
									code="PortalUser.Settings.TwoFactorAuthentication"
									value="Twee factor authenticatie"
								/>
							</CardHeader>
							<PrimaryButton
								label={
                                    <LocalizedText
                                        code='TwoFactorAuthenticationGenerateKey'
                                        value='Nieuwe sleutel genereren'
                                    />
                                }
								onClick={resetTwoFactorAuthentication}
								disabled={!isTwoFactorAuthenticationEnabled}
							/>
						</CardInset>
					</Card>
				</Grid>
            }
        </>
    }

export default observer(PortalUserTwoFactorAuthentication);
