import React, { useCallback, useContext, useEffect, useState } from 'react';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useTypes from '../../../Type/Api/useTypes';
import Input from '../../../Input/Input';
import { ContentProps } from '../Content';
import hasPack from '../../../../../../@Api/Pack/hasPack';
import { Pack } from '../../../../../../@Api/Pack/Pack';
import { EntitySelectionBuilder } from '../../../Selection/Builder/EntitySelectionBuilder';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import LocalizerContext from '../../../../../../@Service/Localization/LocalizerContext';
import Default from '../Default';
import { EntityFieldPath } from '../../../Path/@Model/EntityFieldPath';
import { updateRelationship } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/updateRelationship';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import { setValueByFieldInEntity } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/setValueByFieldInEntity';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import { AddressDetailsResult } from './LookupWizard/Model/AddressDetailsResult';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import Header from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import { AddressLookupWizard } from './LookupWizard/AddressLookupWizard';
import { observer } from 'mobx-react';
import InputGroup from '../../../../../../@Future/Component/Generic/Input/InputGroup/InputGroup';
import ExpansionGroup from '../../../../../../@Future/Component/Generic/ExpansionPanel/Group/ExpansionGroup';
import Icon from '../../../../../../@Future/Component/Generic/Icon/Icon';
import getDatastoreByCode from '../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';
import { commitEntityWithContext } from '../../../../../../@Api/Entity/Commit/Context/Api/Compatibility/commitEntityWithContext';
import { useExpansion } from '../../../Selection/Api/useExpansion';
import { getOrCreateStateByNameAndCountry } from '../../../Coc/Api/getOrCreateStateByNameAndCountry';

export interface AddressProps extends ContentProps
{
}

const Address: React.FC<AddressProps> =
    props =>
    {
        const types = useTypes();
        const hasAccessToPostCodeApi = hasPack(Pack.PostalCode);
        const localizer = useContext(LocalizerContext);
        const [ isLoading ] =
            useExpansion(
                props.entity.entityType,
                rootPath => [
                    rootPath.joinTo(
                        types.Address.RelationshipDefinition.Country,
                        false
                    )
                ],
                () => [
                    props.entity
                ],
                [
                    props.entity,
                    types,
                ]
            );

        // Set default country if component is visible
        // When it becomes invisible and the address is still new, the country is unset (if it was set as default)
        useEffect(
            () =>
            {
                if (!isLoading)
                {
                    const country =
                        props.entity.getRelatedEntityByDefinition(
                            false,
                            types.Address.RelationshipDefinition.Country,
                            props.commitContext
                        );
                    let isCountrySetByDefault = false;

                    if (!country && localizer.countryCode)
                    {
                        new EntitySelectionBuilder(types.Datastore.Country.Type)
                            .where(
                                cb =>
                                    cb.eq(
                                        EntityPath.fromEntityType(types.Datastore.Country.Type)
                                            .field(types.Datastore.Field.Code),
                                        undefined,
                                        localizer.countryCode))
                            .limit(1)
                            .select()
                            .then(
                                countries =>
                                {
                                    if (countries.length > 0
                                        && !props.entity.hasRelationshipsByDefinition(
                                            false,
                                            types.Address.RelationshipDefinition.Country,
                                            props.commitContext)
                                    )
                                    {
                                        updateRelationship(
                                            props.entity,
                                            false,
                                            types.Address.RelationshipDefinition.Country,
                                            countries[0].entity,
                                            props.commitContext
                                        );

                                        isCountrySetByDefault = true;
                                    }
                                });
                    }

                    return () =>
                    {
                        // If any change has been made to the address, the address is no longer new and we do not
                        // want to unset the default country anymore
                        if (isCountrySetByDefault && props.entity.isNew())
                        {
                            updateRelationship(
                                props.entity,
                                false,
                                types.Address.RelationshipDefinition.Country,
                                undefined,
                                props.commitContext
                            );
                        }
                    };
                }
            },
            [
                isLoading,
                props.entity,
                localizer,
                props.commitContext,
            ]);

        const street =
            useEntityValue<string>(
                props.entity,
                types.Address.Field.Street,
                undefined,
                props.commitContext
            );
        const postalCode =
            useEntityValue<string>(
                props.entity,
                types.Address.Field.PostalCode,
                undefined,
                props.commitContext
            );
        const houseNumber =
            useEntityValue<number>(
                props.entity,
                types.Address.Field.HouseNr,
                undefined,
                props.commitContext
            );
        const houseNumberSuffix =
            useEntityValue<string>(
                props.entity,
                types.Address.Field.HouseNrSuffix,
                undefined,
                props.commitContext
            );

        const [ isExpanded, setExpansion ] =
            useState(
                street === undefined
                && postalCode === undefined
                && houseNumber === undefined
                && houseNumberSuffix === undefined
            );

        const updateAddressBySelection =
            useCallback(
                async (address: AddressDetailsResult) =>
                {
                    setExpansion(false);

                    const country =
                        await getDatastoreByCode(
                            types.Datastore.Country.Type,
                            address.countryCode.toLowerCase()
                        );

                    if (country)
                    {
                        updateRelationship(
                            props.entity,
                            false,
                            types.Address.RelationshipDefinition.Country,
                            country,
                            props.commitContext
                        );

                        if (address.state)
                        {
                            const state =
                                await getOrCreateStateByNameAndCountry(
                                    address.state,
                                    country,
                                    props.commitContext
                                );

                            if (state)
                            {
                                updateRelationship(
                                    props.entity,
                                    false,
                                    types.Address.RelationshipDefinition.State,
                                    state,
                                    props.commitContext
                                );
                            }
                        }
                    }
                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.City,
                        address.city,
                        props.commitContext
                    );
                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.Street,
                        address.street,
                        props.commitContext
                    );
                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.HouseNr,
                        address.houseNumber,
                        props.commitContext
                    );
                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.HouseNrSuffix,
                        address.houseNumberSuffix,
                        props.commitContext
                    );

                    let postalCode = address.postalCode;

                    if (postalCode && address.countryCode.toLowerCase() === "nl" && postalCode.length === 6)
                    {
                        postalCode = postalCode.substr(0, 4) + " " + postalCode.substr(4, 2);
                    }

                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.PostalCode,
                        postalCode,
                        props.commitContext
                    );

                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.Latitude,
                        address.latitude,
                        props.commitContext
                    );

                    setValueByFieldInEntity(
                        props.entity,
                        types.Address.Field.Longitude,
                        address.longitude,
                        props.commitContext
                    );

                    return commitEntityWithContext(
                        props.entity,
                        props.commitContext,
                        {
                            isAutoCommit: true
                        }
                    );
                },
                [
                    props.entity,
                    props.commitContext,
                    types,
                ]
            );

        const otherFieldsFilter =
            useCallback(
                (fieldPath: EntityFieldPath) =>
                    fieldPath.relationshipDefinition !== types.Address.RelationshipDefinition.Country
                    && fieldPath.field !== types.Address.Field.PostalCode
                    && fieldPath.field !== types.Address.Field.HouseNr
                    && fieldPath.field !== types.Address.Field.HouseNrSuffix
                    && fieldPath.field !== types.Address.Field.Street
                    && fieldPath.field !== types.Address.Field.City,
                [
                    types
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={15}
        >
            {
                hasAccessToPostCodeApi &&
                <ViewGroupItem>
                    <Card>
                        <ExpansionGroup>
                            <ExpansionPanel
                                id="ExpansionPanel"
                                summary={
                                    <Header
                                        prefix={
                                            <Icon
                                                icon="search"
                                                size={16}
                                            />
                                        }
                                        title={
                                            <LocalizedText
                                                code="Address.Lookup"
                                                value="Adres opzoeken"
                                            />
                                        }
                                        inset
                                    />
                                }
                                expansion={
                                    <CardInset
                                        top={false}
                                    >
                                        <AddressLookupWizard
                                            onLookup={updateAddressBySelection}
                                        />
                                    </CardInset>
                                }
                                expanded={isExpanded}
                                onExpand={() => setExpansion(true)}
                                onCollapse={() => setExpansion(false)}
                            />
                        </ExpansionGroup>
                    </Card>
                </ViewGroupItem>
            }
            <ViewGroupItem>
                <InputGroup>
                    <Input
                        entity={props.entity}
                        field={types.Address.RelationshipDefinition.Country}
                        labelPosition="left"
                        placeholder
                        touched={props.touched}
                        doAutocommit={props.autoCommit}
                        commitContext={props.commitContext}
                    />
                    <Input
                        entity={props.entity}
                        field={types.Address.Field.PostalCode}
                        labelPosition="left"
                        placeholder
                        touched={props.touched}
                        doAutocommit={props.autoCommit}
                        commitContext={props.commitContext}
                    />
                    <Input
                        entity={props.entity}
                        field={types.Address.Field.HouseNr}
                        labelPosition="left"
                        placeholder
                        touched={props.touched}
                        doAutocommit={props.autoCommit}
                        commitContext={props.commitContext}
                    />
                    <Input
                        entity={props.entity}
                        field={types.Address.Field.HouseNrSuffix}
                        labelPosition="left"
                        placeholder
                        touched={props.touched}
                        doAutocommit={props.autoCommit}
                        commitContext={props.commitContext}
                    />
                    <Input
                        entity={props.entity}
                        field={types.Address.Field.Street}
                        labelPosition="left"
                        placeholder
                        touched={props.touched}
                        doAutocommit={props.autoCommit}
                        commitContext={props.commitContext}
                    />
                    <Input
                        entity={props.entity}
                        field={types.Address.Field.City}
                        labelPosition="left"
                        placeholder
                        touched={props.touched}
                        doAutocommit={props.autoCommit}
                        commitContext={props.commitContext}
                    />
                    <Default
                        {...props}
                        filter={otherFieldsFilter}
                    />
                </InputGroup>
            </ViewGroupItem>
        </ViewGroup>;
    };

export default observer(Address);
