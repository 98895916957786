import loadScript from '../../../../../../@Util/Script/loadScript';
import { onEmailMessageChange } from './OutlookAddin';

let Office = (window as any).Office;

export function isInOffice()
{
    return Office?.context?.mailbox || Office?.context?.document;
}

export function isInOutlookAddIn()
{
    return window.location.pathname.endsWith('microsoft-outlook')
        || window.location.pathname.endsWith('microsoft-addin/authenticate')
        || sessionStorage.getItem('isInOutlookAddin') === 'true';
}

export function isInWordAddIn()
{
    return window.location.pathname.endsWith('microsoft-word')
        || sessionStorage.getItem('isInWordAddin') === 'true';
}

function markAsOutlookAddIn()
{
    sessionStorage.setItem('isInOutlookAddin', 'true');
}

function markAsWordAddIn()
{
    sessionStorage.setItem('isInWordAddin', 'true');
}

export function getOffice()
{
    return Office;
}

export async function preInitializeOfficeAddIn()
{
    if (isInOutlookAddIn())
    {
        markAsOutlookAddIn();
    }

    if (isInWordAddIn())
    {
        markAsWordAddIn();
    }

    if (isInOutlookAddIn() || isInWordAddIn())
    {

        // Office js deletes window.history.pushState and window.history.replaceState. Cache them and restore them
        const apiCache = {
            alert: window.alert,
            prompt: window.prompt,
            confirm: window.confirm,
            replaceState: window.history.replaceState,
            pushState: window.history.pushState
        };

        await loadScript('https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js');

        // Office js deletes and overrides several API calls to announce that they are not supported. These are restored.
        window.alert = apiCache.alert;
        window.prompt = apiCache.prompt;
        window.confirm = apiCache.confirm;
        window.history.replaceState = apiCache.replaceState;
        window.history.pushState = apiCache.pushState;

        Office = (window as any).Office;

        return new Promise<any>(
            resolve =>
            {
                Office.onReady(
                    function()
                    {
                        resolve();
                    });

            });
    }
}

export async function initializeWordAddIn()
{
    // no initialization needed
}

export async function initializeOutlookAddIn()
{
    if (isInOutlookAddIn())
    {
        if (
            Office
            && Office.context
        )
        {
            if (Office.context.mailbox && Office.context.mailbox.addHandlerAsync)
            {
                Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () =>
                {
                    // This is the event handler. Do something here.
                    onEmailMessageChange();
                }, () =>
                {
                    // This is callback for addHandlerAsync. This will be called ONCE when the event is registered.
                });
            }

            onEmailMessageChange();
        }
    }
}
