import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import StaticSelectbox from '../../../@Future/Component/Generic/Input/Selectbox/Static/StaticSelectbox';
import localizeText from '../../../@Api/Localization/localizeText';

export interface FontSelectboxProps
{
    onChange: (font: string) => void;
    value?: string;
}

const fonts = [
    '',
    'Arial',
    'Book Antiqua',
    'Bookman Old Style',
    'Calibri',
    'Century Gothic',
    'Comic Sans MS',
    'Consolas',
    'Constantia',
    'Courier New',
    'Courier',
    'Edwardian Script ITC',
    'Exo',
    'Georgia',
    'Helvetica',
    'Helvetica Neue',
    'Lato',
    'Lucida Console',
    'Lucida Sans Unicode',
    'Manrope',
    'Montserrat',
    'Newsreader',
    'Open Sans',
    'Palatino Linotype',
    'Poppins',
    'Roboto',
    'Rubik',
    'Sansa Con Pro',
    'Tahoma',
    'Tenor Sans',
    'Times New Roman',
    'Times',
    'Trebuchet MS',
    'Verdana',
    'monospace',
    'sans-serif',
];

const FontSelectbox: React.FC<FontSelectboxProps> =
    props =>
    {
        const { onChange } = props;

        const options =
            useMemo(
                () =>
                    fonts.map(
                        font =>
                        {
                            if (font === '')
                            {
                                return {
                                    id: '',
                                    value: '',
                                    label: localizeText('Generic.Default', 'Standaard')
                                };
                            }
                            else
                            {
                                return {
                                    id: font,
                                    value: font,
                                    label: font.substring(0, 1).toUpperCase() + font.substring(1, font.length)
                                };
                            }
                        })
                        .sort((lhs, rhs) =>  (lhs.label < rhs.label) ? -1 : 1),
                [
                ]
            );

        const changeCallback =
            useCallback(
                (font: string) =>
                {
                    if (font === '')
                    {
                        onChange(undefined);
                    }
                    else
                    {
                        onChange(font);
                    }
                },
                [
                    onChange
                ]);

        return <StaticSelectbox
            options={options}
            onChange={changeCallback}
            value={!props.value ? '' : props.value}
        />;
    };

export default observer(FontSelectbox);
