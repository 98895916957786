import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Entity } from '../../../../../../@Api/Model/Implementation/Entity';
import { observer, useComputed } from 'mobx-react-lite';
import styles from './Activity.module.scss';
import Header from './Header/Header';
import Relationships from './Relationships/Relationships';
import { Grid } from '@material-ui/core';
import useIsAttachedEntityType from '../../../Item/Navigator/Api/useIsAttachedEntityType';
import useParent from '../../../Item/ParentDescription/Api/useParent';
import HasInsetContext from '../../Context/HasInsetContext';
import EntityViewer from '../../EntityViewer';
import BaseLayout from '../../Shared/BaseLayout/BaseLayout';
import useTypes from '../../../Type/Api/useTypes';
import ProductLinesEditor from './Product/ProductEditor/ProductLinesEditor';
import { EntityPath } from '../../../Path/@Model/EntityPath';
import TimeSheet from './TimeRegistration/TimeSheet/TimeSheet';
import Details from '../../Shared/Details/Details';
import IsCompactContext from '../../Context/IsCompactContext';
import InvolvedList from './InvolvedList/InvolvedList';
import Campaign from './Campaign/Campaign';
import ViewGroup from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import useIsMobile from '../../../../../../@Util/Responsiveness/useIsMobile';
import isHiddenType from '../../../../../../@Api/Metadata/EntityType/isHiddenType';
import isMutable from '../../../../../../@Api/RightProfile/isMutable';
import WorkOrderActions from './WorkOrder/Actions/WorkOrderActions';
import WorkOrderDocument from './WorkOrder/Document/WorkOrderDocument';
import Project from './Project/Project';
import useAggregateResult from '../../../Selection/Hooks/useAggregateResult';
import { Aggregate } from '../../../../DataObject/Model/Aggregate';
import { LowGranularityCompactRepresentation } from '../../../../DataObject/Util/Representation/LowGranularityCompactRepresentation';
import useMetadataSettingFlag from '../../../../../../@Api/Metadata/useMetadataSettingFlag';
import More from '../../../Item/Navigator/More/More';
import { Setting } from '../../../../../../@Api/Settings/Setting';
import ProjectMemberList from './Project/ProjectMemberList/ProjectMemberList';
import RelatedEntityView from '../../Shared/RelatedEntityView/RelatedEntityView';
import getCustomPluralRelatedPaths from './Api/getCustomPluralRelatedPaths';
import LocalizedText from '../../../../Localization/LocalizedText/LocalizedText';
import PageTabBar, { TabFamily } from '../../Shared/Page/TabBar/PageTabBar';
import Card from '../../../../../../@Future/Component/Generic/Card/Card';
import ActivityList from './RelatedActivityList/Model/ActivityList';
import Parameter from '../../../../../../@Api/Automation/Parameter/Parameter';
import uuid from '../../../../../../@Util/Id/uuid';
import EntityValueType from '../../../../../../@Api/Automation/Value/Type/EntityValueType';
import ActivityListItem from './RelatedActivityList/Model/ActivityListItem';
import RelatedActivityListRoot from './RelatedActivityList/RelatedActivityListRoot';
import useRelatedEntity from '../../../../../../@Api/Entity/Hooks/useRelatedEntity';
import { CommitContext } from '../../../../../../@Api/Entity/Commit/Context/CommitContext';
import MileageSheet from './MileageRegistration/MileageSheet/MileageSheet';
import useSetting from '../../../../Setting/Api/useSetting';
import { SettingSource } from '../../../../Setting/SettingStore';
import { SpotlerCampaign } from './SpotlerCampaign/SpotlerCampaign';
import { ApsisCampaign } from './ApsisCampaign/ApsisCampaign';
import { useRoutingState } from '../../../../../../@Service/Router/Model/Api/useRoutingState';
import CourseLessonCronPatternEditor from './Course/LessonCronPatternEditor/CourseLessonCronPatternEditor';
import CardInset from '../../../../../../@Future/Component/Generic/Card/CardInset';
import CourseAttendanceEmailing from './Course/AttendanceEmailing/CourseAttendanceEmailing';
import ExpansionPanel from '../../../../../../@Future/Component/Generic/ExpansionPanel/ExpansionPanel';
import { default as ExpansionPanelHeader } from '../../../../../../@Future/Component/Generic/ExpansionPanel/Header/Header';
import CourseLessonGenerator from './Course/LessonGenerator/CourseLessonGenerator';
import { DateIntervalValue } from '../../../../DataObject/Type/Date/DateInterval/DateIntervalValue';
import getDateIntervalOptions from '../../../../DataObject/Type/Date/DateInterval/Api/getDateIntervalOptions';
import WorkOrderMemberList from './WorkOrder/MemberList/WorkOrderMemberList';
import TimeRegistrationDuration from './TimeRegistration/TimeRegistrationDuration/TimeRegistrationDuration';
import MileageRegistrationDistance from './MileageRegistration/MileageRegistrationDistance/MileageRegistrationDistance';
import RelatedEntityCount from '../../Shared/RelatedEntityCount/RelatedEntityCount';
import useEntityValue from '../../../../../../@Api/Entity/Hooks/useEntityValue';
import { DataObjectRepresentation } from '../../../../DataObject/Model/DataObjectRepresentation';
import { ResizableTwoColumnLayout } from '../../Shared/ResizableTwoColumLayout.tsx/ResizableTwoColumnLayout';
import { useIsNotLgScreen } from '../../../../../../@Util/Responsiveness/useIsNotLgScreen';
import { ApsisForm } from "./ApsisForm/ApsisForm";
import OpenedEntityContext from "../../Context/OpenedEntity/OpenedEntityContext";

export interface ActivityProps
{
    entity: Entity;
    commitContext: CommitContext;
}

const Activity: React.FC<ActivityProps> =
    props =>
    {
        const hasInset = useContext(HasInsetContext);
        const isCompact = useContext(IsCompactContext);
        const isMobile = useIsMobile();
        const isNotLgScreen = useIsNotLgScreen();
        const types = useTypes();

        const isAttachedType = useIsAttachedEntityType(props.entity.entityType);
        const parentEntity = useParent(props.entity);
        const openedEntity = useState(props.entity);
        const [, setOpenedEntity ] = openedEntity;

        const [ normalizedInterval ] = useSetting<DateIntervalValue>(SettingSource.Organization, Setting.ActivityRecurringTotalsInterval);

        const relationship =
            useRelatedEntity(
                props.entity,
                types.Relationship.RelationshipDefinition.Activities,
                true);

        useEffect(
            () =>
            {
                setOpenedEntity(props.entity);
            },
            [
                setOpenedEntity,
                props.entity
            ]);

        const isSalesOpportunity =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.SalesOpportunity.Type),
                [
                    props.entity,
                    types
                ]);
        const isProject =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Project.Type),
                [
                    props.entity,
                    types
                ]);
        const isCampaign =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Campaign.Type),
                [
                    props.entity,
                    types
                ]);
        const isApsisCampaign =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.ApsisCampaign.Type),
                [
                    props.entity,
                    types
                ]);
        const isApsisForm =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.ApsisForm.Type),
                [
                    props.entity,
                    types
                ]
            )
        const isSpotlerCampaign =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.SpotlerCampaign.Type),
                [
                    props.entity,
                    types
                ]
            );
        const isSubscription =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Subscription.Type),
                [
                    props.entity,
                    types
                ]);
        const isWorkOrder =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.WorkOrder.Type),
                [
                    props.entity,
                    types
                ]);
        const isEvent =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Event.Type),
                [
                    props.entity,
                    types
                ]);
        const isCourse =
            useComputed(
                () =>
                    props.entity.entityType.isA(types.Activity.Course.Type),
                [
                    props.entity,
                    types
                ]);

        const [ hasProducts ] = useMetadataSettingFlag(props.entity.entityType, Setting.Metadata.HasProducts);
        const [ hasInvolved ] = useMetadataSettingFlag(props.entity.entityType, Setting.Metadata.HasInvolved);

        const relatedActivityPaths =
            useComputed(
                () => [
                    ...types.Activity.Type
                        .getAllInstantiableTypes()
                        .filter(
                            type =>
                                !(
                                    isHiddenType(type) ||
                                    type.isA(types.Activity.ApsisCampaign.Type) ||
                                    type.isA(types.Activity.ApsisCampaignResult.Type) ||
                                    type.isA(types.Activity.ApsisForm.Type) ||
                                    type.isA(types.Activity.ApsisFormResult.Type)
                                )
                        )
                        .map(
                            activityType =>
                                EntityPath.fromEntity(props.entity)
                                    .joinTo(
                                        types.Activity.RelationshipDefinition.LinkedActivities,
                                        false)
                                    .castTo(activityType)),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Attachments,
                            false),
                    EntityPath.fromEntity(props.entity)
                        .joinTo(
                            types.Entity.RelationshipDefinition.Notes,
                            false)
                ],
                [
                    props.entity,
                    types,
                    isSalesOpportunity,
                    isProject
                ]);

        const isMore =
            useCallback(
                (path: EntityPath) =>
                {
                    if (isMutable(path.entityType.entity))
                    {
                        return false;
                    }
                    else if (path.entityType.isA(types.Activity.Task.Type)
                        || path.entityType.isA(types.Activity.Appointment.Type)
                        || path.entityType.isA(types.Activity.Email.Type)
                        || path.entityType.isA(types.Activity.DigitalSigning.Type)
                        || path.entityType.isA(types.Attachment.Type)
                        || path.entityType.isA(types.Note.Type))
                    {
                        return false;
                    }
                    else if (isProject)
                    {
                        return !path.entityType.isA(types.Activity.Document.Type);
                    }
                    else
                    {
                        return true;
                    }
                },
                [
                    isProject,
                    types
                ]);

        const now =
            useMemo(
                () =>
                    new Date(),
                []);

        const [ activityCount, setActivityCount ] = useState<number>();
        const currency = useEntityValue(props.entity, types.Activity.Field.Currency, undefined, props.commitContext);

        const subscriptionRevenue =
            useComputed(
                () =>
                    isSubscription
                        ? props.entity.getDataObjectValueByField(
                            currency
                                ? types.Activity.Field.TotalRecurringSalesExcludingVatInCurrency
                                : types.Activity.Field.TotalRecurringSalesExcludingVat)
                        : undefined,
                [
                    types,
                    props.entity,
                    isSubscription
                ]
            );

        const productRevenue =
            useAggregateResult(
                types.ProductLine.Type,
                (builder, rootPath) =>
                    builder
                        .where(
                            cb =>

                                cb.and(
                                    ab =>
                                    ab.relatedToEntity(
                                        rootPath
                                            .joinTo(
                                                types.Activity.RelationshipDefinition.ProductLines,
                                                true
                                            ),
                                        props.entity
                                    )
                                    // Exclude expired lines
                                    .or(
                                        ob =>
                                        ob.isNotDefined(
                                            rootPath.field(types.ProductLine.Field.EndDate)
                                        )
                                        .gt(
                                            rootPath.field(types.ProductLine.Field.EndDate),
                                            undefined,
                                            now
                                        )
                                    )
                                )
                        )
                        .aggregateOn(
                            rootPath.field(
                                currency
                                    ? types.ProductLine.Field.TotalInCurrency
                                    : types.ProductLine.Field.Total
                            ),
                            undefined,
                            Aggregate.Sum),
                [
                    types,
                    props.entity,
                    currency,
                    isSubscription,
                ]
            );

        const [ areWorkOrderPricesHidden ] =
            useSetting<boolean>(
                SettingSource.Organization,
                Setting.WorkOrder.HidePrices
            );
        const arePricesHidden =
            useMemo(
                () =>
                    props.entity.entityType.isA(types.Activity.WorkOrder.Type)
                    && areWorkOrderPricesHidden,
                [
                        types,
                        props.entity,
                        areWorkOrderPricesHidden,
                ]
            );
        const productRevenueLabel =
            useComputed(
                () =>
                {
                    if (isSubscription && subscriptionRevenue?.value > 0)
                    {
                        return subscriptionRevenue.toString(
                            new DataObjectRepresentation({
                                ...LowGranularityCompactRepresentation,
                                currency: currency
                            })
                        )
                    }
                    else if (
                        !arePricesHidden &&
                        (productRevenue && productRevenue.aggregates[0].value > 0)
                    )
                    {
                        return productRevenue.aggregates[0].toString(
                            new DataObjectRepresentation({
                                ...LowGranularityCompactRepresentation,
                                currency: currency
                            })
                        );
                    }
                    else
                    {
                        return undefined;
                    }
                },
                [
                    isSubscription,
                    arePricesHidden,
                    productRevenue,
                    subscriptionRevenue,
                    currency,
                ]
            );

        const productTabTooltip =
            useMemo(
                () =>
                    {
                        if (isSubscription)
                        {
                            const interval = getDateIntervalOptions()
                                .find(option => option.id === normalizedInterval.type)
                            const freqString =
                                normalizedInterval.frequency !== 1
                                    ? `${normalizedInterval.frequency} `
                                    : '';

                            return `${
                                currency 
                                    ? types.Activity.Field.TotalRecurringSalesExcludingVatInCurrency.getName()
                                    : types.Activity.Field.TotalRecurringSalesIncludingVat.getName()
                            }(${freqString}${interval?.label})`
                        }
                        else
                        {
                            return undefined;
                        }

                   },
                [
                    currency,
                    isSubscription,
                    normalizedInterval.frequency,
                    normalizedInterval.type,
                    types
                ]
            );

        const pluralRelatedPaths =
            useComputed(
                () =>
                    [
                        ...getCustomPluralRelatedPaths(props.entity.entityType),
                        ...isEvent
                            ?
                                [
                                    EntityPath.fromEntity(props.entity)
                                        .joinTo(
                                            types.Activity.Event.RelationshipDefinition.Participations,
                                            false)
                                ]
                            :
                                []
                    ],
                [
                    props.entity,
                    isEvent
                ]);

        const activityList =
            useMemo(
                () =>
                    new ActivityList(
                        new Parameter(
                            uuid(),
                            new EntityValueType(props.entity.entityType),
                            true,
                            props.entity.entityType.getName()),
                        relatedActivityPaths.map(
                            path =>
                                new ActivityListItem(
                                    new Parameter(
                                        uuid(),
                                        new EntityValueType(path.entityType),
                                        true,
                                        path.entityType.getName()),
                                    path,
                                    true,
                                    isMore(path)))),
                [
                    props.entity,
                    relatedActivityPaths,
                    isMore
                ]);

        const isStandaloneAttachedType = isAttachedType && parentEntity === undefined;
        const leftColumn =
            useMemo(
                () =>
                    <Grid
                        container
                        spacing={2}
                    >
                        {
                            !isProject &&
							<Grid
								item
								xs={12}
							>
								<Details
									entity={props.entity}
									appendix={
                                        isCourse &&
										<ExpansionPanel
											id="CourseLessonPlanning"
											summary={
                                                <ExpansionPanelHeader
                                                    title={
                                                        <LocalizedText
                                                            code="Activity.Course.LessonPlanning"
                                                            value="Lesplanning"
                                                        />
                                                    }
                                                    inset
                                                />
                                            }
											expansion={
                                                <CardInset
                                                    top={false}
                                                >
                                                    <ViewGroup
                                                        orientation="vertical"
                                                        spacing={10}
                                                    >
                                                        <ViewGroupItem>
                                                            <CourseLessonCronPatternEditor
                                                                entity={props.entity}
                                                                commitContext={props.commitContext}
                                                            />
                                                        </ViewGroupItem>
                                                        <ViewGroupItem>
                                                            <CourseLessonGenerator
                                                                entity={props.entity}
                                                            />
                                                        </ViewGroupItem>
                                                    </ViewGroup>
                                                </CardInset>
                                            }
										/>
                                    }
								/>
							</Grid>
                        }
                        {
                            !(isCompact || isMobile) &&
							<Grid
								item
								xs={12}
							>
								<Relationships
									entity={props.entity}
								/>
							</Grid>
                        }
                        {
                            isProject &&
							<Grid
								item
								xs={12}
							>
								<ProjectMemberList
									entity={props.entity}
								/>
							</Grid>
                        }
                        {
                            isWorkOrder &&
							<Grid
								item
								xs={12}
							>
								<WorkOrderMemberList
									entity={props.entity}
								/>
							</Grid>
                        }
                        {
                            hasInvolved &&
							<Grid
								item
								xs={12}
							>
								<InvolvedList
									entity={props.entity}
								/>
							</Grid>
                        }
                    </Grid>,
                [hasInvolved, isCompact, isCourse, isMobile, isProject, isWorkOrder, props.commitContext, props.entity]
            );

        const [ openedChildEntity ] = useContext(OpenedEntityContext)
        const [childEntity, setChildEntity] = useState<Entity>();

        useEffect(
            () =>
            {
                if (openedChildEntity)
                {
                    setChildEntity(openedChildEntity)
                }
            },
            [
                openedChildEntity
            ]
        );

        const defaultTabId =
            useMemo(
                () =>
                    isSubscription && !childEntity
                        ? 'Static2:Products'
                        : isStandaloneAttachedType
                            ? 'Static:Details'
                            : 'Static:Activities',
                [
                    isSubscription,
                    childEntity,
                    isStandaloneAttachedType
                ]
            )

        const [ tab, setTab ] =
            useRoutingState(
                `Entity.${props.entity.id}.TabId`,
                () =>
                    defaultTabId
            );

        useEffect(
            () =>
            {
                if (childEntity)
                {
                    setTab(defaultTabId);
                }
            },
            [
                childEntity,
                setTab,
                defaultTabId
            ]);

        const tabFamilies =
            useMemo<TabFamily[]>(
                () => [
                    {
                        id: 'Static',
                        tabs: [
                            ...isStandaloneAttachedType
                                ? [
                                    {
                                        id: 'Details',
                                        name:
                                            <LocalizedText
                                                code="Generic.Details"
                                                value="Details"
                                            />,
                                        content:
                                            () =>
                                                <Card>
                                                    <More
                                                        entity={props.entity}
                                                        disableInset
                                                    />
                                                </Card>,
                                        isVisible:
                                            () =>
                                                isStandaloneAttachedType,
                                    }
                                ]
                                : [
                                    {
                                        id: 'Details',
                                        name:
                                            <LocalizedText
                                                code="Generic.Details"
                                                value="Details"
                                            />,
                                        content:
                                            () =>
                                                leftColumn,
                                        isVisible:
                                            () =>
                                                isNotLgScreen,
                                    }
                                ],
                            {
                                id: 'Activities',
                                name:
                                    <LocalizedText
                                        code="Generic.Activities"
                                        value="Activiteiten"
                                    />,
                                nameAppendix:
                                    activityCount > 0 && activityCount,
                                content:
                                    tabProps =>
                                        <RelatedActivityListRoot
                                            list={activityList}
                                            entity={props.entity}
                                            onCount={setActivityCount}
                                            showCreationItem
                                            {...tabProps}
                                        />
                            }
                        ]
                    },
                    {
                        id: 'PluralRelatedPaths',
                        tabs:
                            pluralRelatedPaths.map(
                                path =>
                                    ({
                                        id: path.id,
                                        name: path.getName(undefined, false),
                                        nameAppendix:
                                            <RelatedEntityCount
                                                entity={props.entity}
                                                path={path}
                                            />,
                                        content:
                                            () =>
                                                <RelatedEntityView
                                                    entity={props.entity}
                                                    path={path}
                                                />
                                    }))
                    },
                    {
                        id: 'Static2',
                        tabs: [
                            {
                                id: 'Products',
                                name:
                                    <LocalizedText
                                        code="Generic.Products"
                                        value="Producten"
                                    />,
                                tooltip: productTabTooltip,
                                nameAppendix: productRevenueLabel,
                                isVisible: () => hasProducts,
                                content:
                                    () =>
                                        <Card>
                                            <ProductLinesEditor
                                                entity={props.entity}
                                                commitContext={props.commitContext}
                                                onClose={() => setTab('Static:Activities')}
                                                addNewLineIfEmpty
                                            />
                                        </Card>
                            },
                            {
                                id: 'Hours',
                                name:
                                    <LocalizedText
                                        code="Generic.Hours"
                                        value="Uren"
                                    />,
                                nameAppendix:
                                    <TimeRegistrationDuration
                                        entity={props.entity}
                                    />,
                                isVisible: () => hasProducts && types.TimeRegistration.Type !== undefined,
                                content:
                                    () =>
                                        <TimeSheet
                                            relationship={relationship}
                                            activity={props.entity}
                                        />
                            },
                            {
                                id: 'Mileage',
                                name:
                                    <LocalizedText
                                        code="Generic.Mileage"
                                        value="Kilometers"
                                    />,
                                nameAppendix:
                                    <MileageRegistrationDistance
                                        entity={props.entity}
                                    />,
                                isVisible: () => hasProducts && types.MileageRegistration.Type !== undefined,
                                content:
                                    () =>
                                        <MileageSheet
                                            relationship={relationship}
                                            activity={props.entity}
                                        />
                            },
                            {
                                id: 'WorkOrderPdf',
                                name:
                                    <LocalizedText
                                        code="Generic.PDF"
                                        value="PDF"
                                    />,
                                isVisible: () => isWorkOrder,
                                content:
                                    () =>
                                        <WorkOrderDocument
                                            entity={props.entity}
                                        />
                            },
                            {
                                id: 'AttendanceEmailing',
                                name:
                                    <LocalizedText
                                        code="Course.EmailAttendees"
                                        value="Deelnemers e-mailen"
                                    />,
                                isVisible: () => isCourse,
                                content:
                                    () =>
                                        <CourseAttendanceEmailing
                                            course={props.entity}
                                            commitContext={props.commitContext}
                                            onSend={
                                                () =>
                                                    setTab('Static:Activities')
                                            }
                                        />
                            },
                        ]
                    }
                ],
                [
                    activityCount,
                    pluralRelatedPaths,
                    productTabTooltip,
                    productRevenueLabel,
                    props.entity,
                    props.commitContext,
                    isStandaloneAttachedType,
                    leftColumn,
                    isNotLgScreen,
                    activityList,
                    hasProducts,
                    setTab,
                    types.TimeRegistration.Type,
                    types.MileageRegistration.Type,
                    relationship,
                    isWorkOrder,
                    isCourse
                ]
            );

        const rightColumn =
            <>
                {
                    isWorkOrder &&
                    <div
                        className={styles.actions}
                    >
                        <WorkOrderActions
                            entity={props.entity}
                        />
                    </div>
                }
                {
                    !isProject &&
                    <PageTabBar
                        entity={props.entity}
                        tabFamilies={tabFamilies}
                        tabId={tab}
                        onChangeTab={setTab}
                        commitContext={props.commitContext}
                    />
                }
                {
                    isProject &&
                    <Project
                        project={props.entity}
                        commitContext={props.commitContext}
                    />
                }
            </>;

        if (isAttachedType)
        {
            if (parentEntity)
            {
                return <EntityViewer
                    entity={parentEntity}
                    openedEntity={openedEntity}
                    inset={hasInset}
                    compact={isCompact}
                />;
            }
            else
            {
                return <BaseLayout>
                    <ViewGroup
                        orientation="vertical"
                        spacing={15}
                    >
                        <ViewGroupItem>
                            <Header
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ResizableTwoColumnLayout
                                id={`EntityType.${types.Activity.Type.id}`}
                                leftColumn={
                                    <Relationships
                                        entity={props.entity}
                                    />
                                }
                                rightColumn={
                                    <PageTabBar
                                        entity={props.entity}
                                        tabFamilies={tabFamilies}
                                        tabId={tab}
                                        onChangeTab={setTab}
                                        commitContext={props.commitContext}
                                    />
                                }
                                compact={isCompact}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </BaseLayout>;
            }
        }
        else if (isCompact)
        {
            return <Header
                entity={props.entity}
            />;
        }
        else if (isCampaign)
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem
                            className={styles.header}
                        >
                            <Header
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <Campaign
                                entity={props.entity}
                                commitContext={props.commitContext}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </BaseLayout>
        }
        else if (isSpotlerCampaign)
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem
                            className={styles.header}
                        >
                            <Header
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <SpotlerCampaign
                                spotlerCampaign={props.entity}
                                commitContext={props.commitContext}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </BaseLayout>
        }
        else if (isApsisCampaign)
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem
                            className={styles.header}
                        >
                            <Header
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ApsisCampaign
                                apsisCampaign={props.entity}
                                commitContext={props.commitContext}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </BaseLayout>
        }
        else if (isApsisForm)
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <ViewGroup
                        orientation="vertical"
                        spacing={10}
                    >
                        <ViewGroupItem
                            className={styles.header}
                        >
                            <Header
                                entity={props.entity}
                            />
                        </ViewGroupItem>
                        <ViewGroupItem>
                            <ApsisForm
                                apsisForm={props.entity}
                                commitContext={props.commitContext}
                            />
                        </ViewGroupItem>
                    </ViewGroup>
                </div>
            </BaseLayout>
        }
        else
        {
            return <BaseLayout>
                <div
                    className={styles.root}
                >
                    <div
                        className={styles.header}
                    >
                        <Header
                            entity={props.entity}
                        />
                    </div>
                    <div
                        className={styles.content}
                    >
                        {
                            isNotLgScreen
                                ? rightColumn
                                : <ResizableTwoColumnLayout
                                    id={`EntityType.${types.Activity.Type.id}`}
                                    leftColumn={leftColumn}
                                    rightColumn={rightColumn}
                                    compact={isCompact}
                                />
                        }
                    </div>
                </div>
            </BaseLayout>;
        }
    };

export default observer(Activity);
