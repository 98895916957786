import React, { useCallback, useContext, useMemo, useState } from 'react';
import useTypes from '../../../../Type/Api/useTypes';
import { EntitySelectionBuilder } from '../../../../Selection/Builder/EntitySelectionBuilder';
import Selectbox from '../../../../Selectbox/Selectbox';
import { Entity } from '../../../../../../../@Api/Model/Implementation/Entity';
import useEntityValue from '../../../../../../../@Api/Entity/Hooks/useEntityValue';
import { AddressLookupSelectbox } from './Selectbox/AddressLookupSelectbox';
import Input from '../../../../../../../@Future/Component/Generic/Input/Input/Input';
import { AddressDetailsResult } from './Model/AddressDetailsResult';
import ViewGroup from '../../../../../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import LocalizerContext from '../../../../../../../@Service/Localization/LocalizerContext';
import useAsyncResult from '../../../../../../../@Util/Async/useAsyncResult';
import getDatastoreByCode from '../../../../../../../@Api/Entity/Bespoke/Datastore/getDatastoreByCode';

const availableCountries = [ 'NL', 'BE', 'DE', 'LU', 'AT', 'CH', 'FR' ];

export interface AddressLookupWizardProps
{
    onLookup: (address: AddressDetailsResult) => void;
    autoFocus?: boolean;
}

export const AddressLookupWizard: React.FC<AddressLookupWizardProps> =
    ({
        onLookup,
        autoFocus,
     }) =>
    {
        const types = useTypes();
        const localizer = useContext(LocalizerContext);
        const [ country, setCountry ] = useState<Entity | undefined>(undefined);
        const [ isCountryChanged, setIsCountryChanged ] = useState<boolean>(false);

        useAsyncResult(
            () =>
                localizer.countryCode &&
                getDatastoreByCode(
                    types.Datastore.Country.Type,
                    localizer.countryCode
                ).then(setCountry),
            [
                types,
                localizer,
            ]
        );
        const countryCode =
            useEntityValue<string>(
                country,
                types.Datastore.Field.Code
            );
        const countrySelections =
            useMemo(
                () => [
                    EntitySelectionBuilder.build(
                        types.Datastore.Country.Type,
                        (builder, rootPath) =>
                            builder.where(
                                cb =>
                                    cb.or(
                                        ob =>
                                            availableCountries
                                                .forEach(
                                                    countryCode =>
                                                        ob.eq(
                                                            rootPath.field(types.Datastore.Field.Code),
                                                            undefined,
                                                            countryCode
                                                        )
                                                )
                                    )
                            )
                    )
                ],
                [
                    types
                ]
            );

        const onChangeCountry =
            useCallback(
                (value: Entity) =>
                {
                    setCountry(value);
                    setIsCountryChanged(true);
                },
                [
                    setCountry,
                    setIsCountryChanged
                ]);

        return <ViewGroup
            orientation="vertical"
            spacing={10}
        >
            <Input
                labelPosition="top"
                label={types.Datastore.Country.Type.getName()}
            >
                <Selectbox
                    selections={countrySelections}
                    value={country}
                    onChange={onChangeCountry}
                    clearable
                    disableUnderline={false}
                />
            </Input>
            {
                countryCode &&
                <Input
                    labelPosition="top"
                    label={types.Address.Type.getName()}
                >
                    <AddressLookupSelectbox
                        countryCode={countryCode}
                        onLookup={onLookup}
                        autoFocus={autoFocus || isCountryChanged}
                    />
                </Input>
            }
        </ViewGroup>;
    };
