import React, { useCallback, useMemo, useState } from 'react';
import { observer, useComputed } from 'mobx-react-lite';
import useAsyncResult from '../../../../../@Util/Async/useAsyncResult';
import performAction from '../../../../../@Api/Entity/performAction';
import Centered from '../../../../../@Future/Component/Generic/Centered/Centered';
import Loader from '../../../../../@Future/Component/Generic/Loader/Loader';
import DocumentViewer from '../../../../../@Future/Component/Generic/Input/DocumentEditor/DocumentViewer';
import FormEntityContext, { FormContext } from '../Context/FormContext';
import { observable, runInAction } from 'mobx';
import { FormFieldValue } from '../Model/FormFieldValue';
import Form from '../Model/Form';
import { DataObject } from '../../../DataObject/Model/DataObject';
import LocalizedText from '../../../Localization/LocalizedText/LocalizedText';
import localizeText from '../../../../../@Api/Localization/localizeText';
import { getQueryParameterFromUrl } from '../../../../../@Util/Url/getQueryParameter';
import { isStringNumeric } from '../../../../../@Util/String/isStringNumeric';
import { NumberType } from '../../../DataObject/Type/Number/NumberType';
import FormFieldOption from '../Model/FormFieldOption';

export interface FormPageProps
{
    formId: string;
    submitted?: boolean;
    safeMode?: boolean;
}

const FormPage: React.FC<FormPageProps> =
    ({
         formId,
         submitted,
         safeMode
     }) =>
    {
        const [ isSubmitted, setSubmitted ] = useState(submitted);
        const [ valueByFieldId ] =
            useState(
                () =>
                    observable.map<string, FormFieldValue>([], { deep: false })
            );
        const [ result, isLoading ] =
            useAsyncResult(
                async () =>
                {
                    const result =
                        await performAction(
                            undefined,
                            {
                                code: 'Form.Get',
                                parameters: {
                                    id: formId
                                }
                            }
                        );

                    if (result.result)
                    {
                        const form = result.result as Form;
                        const defaultValueByFieldId = new Map<string, FormFieldValue>();

                        for (const field of form.fields)
                        {
                            const defaultValue = getQueryParameterFromUrl(field.id);

                            if (defaultValue)
                            {
                                if (field.type === 'Option')
                                {
                                    try
                                    {
                                        const defaultOption =
                                            await performAction<FormFieldOption>(
                                                undefined,
                                                {
                                                    code: 'Form.GetFieldOptionById',
                                                    parameters: {
                                                        formId: form.id,
                                                        fieldId: field.id,
                                                        optionId: defaultValue,
                                                    },
                                                }
                                            );
                                        defaultValueByFieldId.set(
                                            field.id,
                                            defaultOption.result
                                        );
                                    }
                                    catch (e)
                                    {
                                        console.error(
                                            `Could not resolve default option with ID: ${defaultValue} for field with ID: ${field.id}`,
                                            e
                                        );
                                    }
                                }
                                else
                                {
                                    const type = DataObject.getTypeById(field.type);

                                    defaultValueByFieldId.set(
                                        field.id,
                                        DataObject.constructFromTypeIdAndValue(
                                            field.type,
                                            type instanceof NumberType && isStringNumeric(defaultValue)
                                                ? parseFloat(defaultValue)
                                                : defaultValue
                                        )
                                    );
                                }
                            }
                        }

                        if (defaultValueByFieldId.size > 0)
                        {
                            runInAction(
                                () =>
                                    defaultValueByFieldId.forEach(
                                        (value, field) =>
                                            valueByFieldId.set(
                                                field,
                                                value
                                            )
                                    )
                            );
                        }
                    }

                    return result;
                },
            [
                formId,
                valueByFieldId,
            ]
        );

        const form =
            useMemo<Form>(
                () =>
                    result?.result,
                [
                    result
                ]);

        const save =
            useCallback(
                () =>
                {
                    const files = new Map<string, File>();
                    const submission: any = {
                        formId: form.id,
                        values: []
                    };

                    for (const field of form.fields)
                    {
                        const fieldHasValue = valueByFieldId.has(field.id) && valueByFieldId.get(field.id) !== undefined;

                        if (field.isRequired
                            && !fieldHasValue)
                        {
                            window.alert(
                                localizeText(
                                    'Generic.FieldEmpty',
                                    'Het veld ${field} is niet ingevuld.',
                                    {
                                        field: field.name
                                    }
                            ));
                            return Promise.resolve(false);
                        }

                        if (fieldHasValue)
                        {
                            const value = valueByFieldId.get(field.id);
                            const serializedValue = getSerializedValueFromField(value, files);

                            submission.values.push({
                                fieldId: field.id,
                                value: serializedValue
                            });
                        }
                    }

                    return performAction(
                        undefined,
                        {
                            code: 'Form.Submit',
                            parameters: {
                                submission: submission
                            },
                            files: files
                        })
                        .then(
                            () =>
                            {
                                setSubmitted(true);
                                return Promise.resolve(true);
                            }
                        )
                        .catch(
                            () =>
                            {
                                return Promise.resolve(false)
                            }
                        );
                },
                [
                    form,
                    valueByFieldId,
                    setSubmitted
                ]);

        const formContext =
            useComputed<FormContext>(
                () => ({
                    form: result?.result,
                    valueByFieldId: valueByFieldId,
                    onSubmit: save,
                }),
                [
                    result,
                    valueByFieldId,
                    save
                ]);

        if (isLoading
            || !result)
        {
            return <Centered
                horizontal
                vertical
            >
                <Loader />
            </Centered>;
        }
        else
        {
            if (isSubmitted
                && result.result?.submittedLayout)
            {
                return <FormEntityContext.Provider
                    value={formContext}
                >
                    <DocumentViewer
                        definition={result.result.submittedLayout}
                        safeMode={safeMode}
                    />
                </FormEntityContext.Provider>;
            }
            else if (!isSubmitted
                && result.result?.layout)
            {
                return <FormEntityContext.Provider
                    value={formContext}
                >
                    <DocumentViewer
                        definition={result.result.layout}
                        safeMode={safeMode}
                    />
                </FormEntityContext.Provider>;
            }
            else
            {
                return <Centered
                    horizontal
                >
                    <LocalizedText
                        code="Form.NotFound"
                        value="Formulier niet gevonden"
                    />
                </Centered>;
            }
        }
    };

function getSerializedValueFromField(
    value: any,
    files: Map<string, File>
): any
{
    let serializedValue: any;

    if (value instanceof DataObject)
    {
        serializedValue =
            // https://stackoverflow.com/questions/37199019/method-set-prototype-add-called-on-incompatible-receiver-undefined
            value.data.descriptor(files.set.bind(files));
    }
    else if (Array.isArray(value))
    {
        serializedValue =
            value.map(
                fieldValue =>
                    getSerializedValueFromField(
                        fieldValue, 
                        files
                    )
                )
    }
    else
    {
        serializedValue = value.id;
    }

    return serializedValue;
}

export default observer(FormPage);
