import Parameter from '../Parameter/Parameter';
import Dependency from '../Parameter/Dependency';
import Validation from '../Validation/Validation';
import { observable } from 'mobx';
import Predicate from '../Function/Computation/Predicate/Predicate';
import PrimitiveValueType from '../Value/Type/PrimitiveValueType';
import { loadModuleDirectly } from '../../../@Util/DependencyInjection/Injection/DependencyInjection';
import { DataObjectStore } from '../../../@Component/Domain/DataObject/DataObjectStore';
import localizeText from '../../Localization/localizeText';

export default abstract class Trigger
{
    // ------------------------- Properties -------------------------

    static DateParameterId = 'Date';

    @observable.ref predicate: Predicate;

    // ------------------------ Constructor -------------------------

    constructor(predicate: Predicate)
    {
        this.predicate = predicate;
    }

    // ----------------------- Initialization -----------------------

    // -------------------------- Computed --------------------------

    // -------------------------- Actions ---------------------------

    // ------------------------ Public logic ------------------------

    abstract getParameters(): Parameter<any>[];

    getDateParameter()
    {
        return new Parameter(
            Trigger.DateParameterId,
            new PrimitiveValueType(
                loadModuleDirectly(DataObjectStore).getTypeById('DateTime')),
            true,
            localizeText(
                'Generic.Date',
                'Datum'
            )
        );
    }

    validate(): Validation[]
    {
        if (this.predicate)
        {
            return this.predicate.validate();
        }
        else
        {
            return [];
        }
    }

    abstract getDependencies(): Dependency[];

    toDescriptor(): any
    {
        const descriptor: any = {};

        this.augmentDescriptor(descriptor);

        if (this.predicate)
        {
            descriptor.predicate = this.predicate.toDescriptor();
        }

        return descriptor;
    }

    abstract augmentDescriptor(descriptor: any);

    abstract getName(): string;

    // ----------------------- Private logic ------------------------
}
