import React from 'react';
import { GoogleMapLocation } from './GoogleMap';
import { observer } from 'mobx-react';
import Card from '../../../@Future/Component/Generic/Card/Card';
import CardInset from '../../../@Future/Component/Generic/Card/CardInset';
import useTypes from '../../Domain/Entity/Type/Api/useTypes';
import Item from '../../Domain/Entity/Summary/Item/Item';
import ViewableName from '../../Domain/Entity/Item/Name/ViewableName/ViewableName';
import ViewGroup from '../../../@Future/Component/Generic/ViewGroup/ViewGroup';
import ViewGroupItem from '../../../@Future/Component/Generic/ViewGroup/ViewGroupItem';
import { makeStyles } from '@material-ui/styles';
import { lightTextColor } from '../../../@Resource/Theme/Theme';

export interface GoogleMapsLabelProperties
{
    lat: number;
    lng: number;
    location: GoogleMapLocation;
}
makeStyles({
    description: {
        fontSize: '13px',
        color: lightTextColor
    }
});
export const GoogleMapsLabel: React.FC<GoogleMapsLabelProperties> = observer(
    ({
        location
     }) =>
{
    const types = useTypes();

    const addressLine =
        location.address
            ? types.Address.Functions.getAddressLine(location.address)
            : '';

    const summaryField = {
        id: `location.${location.address.uuid}`,
        icon: 'location_on',
        link: location.link,
        title: addressLine,
        tooltip: '', //addressRspDef.getName(false, entity.entityType),
        value: undefined,
        entity: location.address,
    }

    return <Card
        style={{width: 'max-content'}}
    >
        <CardInset>
            <ViewGroup orientation="vertical" spacing={5}>
                <ViewGroupItem>
                    <ViewableName
                        entity={location.addressProvider}
                        link
                    />
                </ViewGroupItem>
                <ViewGroupItem>
                    <Item
                        field={summaryField}
                        clickable
                    />
                </ViewGroupItem>
            </ViewGroup>
        </CardInset>
    </Card>

});
